export enum AddSourcesPreviousAction {
  'ADD_SOURCES' = 'add-sources',
  'AUTHORING_REQUEST' = 'authoring-request',
  'DYNAMIC_MAPPING' = 'dynamic-mapping',
}

export const FILTER_OPTIONS = {
  SEARCH_THRESHOLD: 5,
} as const;

export const UPLOAD_MESSAGES = {
  ALL_SUCCESS: 'All items have been successfully uploaded.',
  ALL_ERROR: 'Items encountered errors while uploading, please upload again.',
  PARTIAL_SUCCESS: (success: number, error: number) =>
    `${success} items uploaded successfully, ${error} encountered errors please reupload these files.`,
  UPLOAD_IN_PROGRESS: 'Upload in Progress',
} as const;

export const UPLOAD_ALERT_TYPES = {
  SUCCESS: 'positive',
  ERROR: 'negative',
  WARNING: 'warning',
} as const;
