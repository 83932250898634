import { getCatalogItems, getProcessidsCatalogItems } from '../../../../services/api';
import { CatalogItems, ItemType, sortOrder, SortParams } from '../../../../services/api/types';

export const getAllCatalogItems = async (
  type: ItemType,
  pagination = { page: 1, perPage: 10 },
  sort = { column: 'name', type: sortOrder.ASC } as SortParams<Partial<CatalogItems>>,
) => {
  try {
    const { data } = await getCatalogItems({ filters: { type: type }, pagination, sort });
    return data.data;
  } catch (error) {
    console.error('Error fetching materials:', error);
    return [];
  }
};

export const getAllCatalogItemsWithPagination = async (
  type: ItemType | Array<ItemType>,
  pagination = { page: 1, perPage: 20 },
  sort = { column: 'name', type: sortOrder.ASC } as SortParams<Partial<CatalogItems>>,
) => {
  try {
    const { data } = await getCatalogItems({ filters: { type: type }, pagination, sort });
    return { items: data.data, total: data.total, prevOffset: pagination.page };
  } catch (error) {
    console.error('Error fetching filters items:', error);
    throw new Error(`Error fetching filters items: ${error}`);
  }
};

export const getProcessidsByMaterialIds = async (ids: number[]) => {
  if (ids.length === 0) {
    return [];
  }
  try {
    const { data } = await getProcessidsCatalogItems(ids);
    return data?.groupedProcessids || [];
  } catch (error) {
    console.error('Error fetching processIds:', error);
    return [];
  }
};
