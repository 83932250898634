import { useContext, useState } from 'react';
import { MappingContext } from '../../contexts/MappingContext';
import {
  getElement,
  getCellElement,
  removeBackgroundColor,
  updateAllSpanBackgroundColors,
  escapeHtml,
} from '../../helpers/generalizationHelpers';
import { SetStateFunction } from '../../types';
import { v4 } from 'uuid';
import { AUTHORING_COLORS } from '../../constants';

export const useTextSelection = () => {
  const { editor1Ref, editor2Ref } = useContext(MappingContext);

  const [targetSelection, setTargetSelection] = useState<HTMLElement | null>();
  const [sourceSelection, setSourceSelection] = useState<HTMLElement | null>();

  const templateSelectionOnClickHandler = (target: HTMLElement): string | null => {
    if (editor1Ref.current.selection.getSelectedBlocks().length > 1) return null;

    const spans = editor1Ref.current
      .getBody()
      .querySelectorAll('span[data-selectionid][data-sourceid]');
    updateAllSpanBackgroundColors(spans);

    const selection = editor1Ref.current.selection.getSel();
    const selectedText = selection ? selection.toString() : '';

    const cellElement = getCellElement(target, editor1Ref.current.getBody());

    const nodeId =
      cellElement?.getAttribute('data-nodeid') ??
      target.getAttribute('data-nodeid') ??
      selection?.anchorNode?.parentElement?.getAttribute('data-nodeid');

    nodeId &&
      updateDocumentSelection(
        selectedText,
        !!nodeId,
        setTargetSelection,
        targetSelection,
        editor1Ref,
        nodeId,
      );

    resetSourceTextHighlight();

    return selectedText ? nodeId : highlightSpan(target);
  };

  const highlightSpan = (element: HTMLElement): string | null => {
    if (element.nodeName === 'SPAN' && element.getAttribute('data-selectionid')) {
      const parent = element.parentNode;
      if (parent) {
        return (parent as HTMLElement).getAttribute('data-nodeid');
      }
    }
    const hasDataHighlight = Array.from(element.getElementsByTagName('*')).some((ele: any) =>
      ele.hasAttribute('data-highlight'),
    );
    return hasDataHighlight ? element.getAttribute('data-nodeid') : null;
  };

  const sourceSelectionOnClickHandler = (target: HTMLElement): string | null => {
    if (!targetSelection || target.nodeName === 'DIV') return null;

    const cellElement = getCellElement(target, editor2Ref.current.getBody());
    const clickedNodeId = target.getAttribute('data-nodeid') || '';
    const selection = editor2Ref.current.selection.getSel();

    const selectedText = selection ? selection.toString() : '';

    const nodeId = cellElement?.getAttribute('data-nodeid') ?? target.getAttribute('data-nodeid');

    updateDocumentSelection(
      selectedText,
      !!nodeId,
      setSourceSelection,
      sourceSelection,
      editor2Ref,
      clickedNodeId,
    );

    return selectedText && (cellElement?.getAttribute('data-nodeid') ?? clickedNodeId);
  };

  const processSelection = (
    sourceSelection: HTMLElement | null | undefined,
    targetSelection: HTMLElement | null | undefined,
    editor2Ref: any,
    transferSelection = false,
  ) => {
    if (sourceSelection && targetSelection) {
      const sourceDocumentText = sourceSelection.querySelector('[data-highlight="active"]');
      const updatedTargetNode = targetSelection.querySelector('[data-highlight="active"]');
      const oldTargetNodeValue = targetSelection?.innerHTML;

      if (sourceDocumentText && updatedTargetNode) {
        sourceDocumentText.setAttribute('data-highlight', 'partial');
        updatedTargetNode.setAttribute('data-highlight', 'partial');
        const sourceNodeId = sourceSelection.getAttribute('data-nodeId');
        const sourceCellElement = getCellElement(sourceDocumentText, editor2Ref);
        let tdSourceId: string | null = null;
        if (sourceNodeId) {
          if (sourceCellElement) {
            tdSourceId = sourceCellElement.getAttribute('data-nodeId');
          }
          sourceDocumentText.setAttribute('data-sourceid', tdSourceId ?? sourceNodeId);
          updatedTargetNode.setAttribute('data-sourceid', tdSourceId ?? sourceNodeId);
          const selectionId = v4();
          sourceDocumentText.setAttribute('data-selectionid', selectionId);
          updatedTargetNode.setAttribute('data-selectionid', selectionId);
        }
        if (transferSelection) updatedTargetNode.innerHTML = sourceDocumentText.innerHTML;
      }
      setSourceSelection(null);
      setTargetSelection(null);

      return {
        sourceValue: removeBackgroundColor(sourceSelection.innerHTML),
        targetValue: removeBackgroundColor(targetSelection.innerHTML),
        oldTargetNodeValue: removeBackgroundColor(oldTargetNodeValue),
      };
    }
  };

  const updateTextSelectionNode = () => {
    return processSelection(sourceSelection, targetSelection, editor2Ref, true);
  };

  const linkTextSelectionNode = () => {
    return processSelection(sourceSelection, targetSelection, editor2Ref);
  };

  const updateDocumentSelection = (
    selectedText: string,
    isTdElementWithDataNodeId: boolean,
    setSelection: SetStateFunction<any>,
    selection: any,
    editorRef: any,
    clickedNodeId: string,
  ) => {
    if (!selectedText || !isTdElementWithDataNodeId) {
      return;
    }
    if (selection) {
      selection
        .querySelector("span[data-highlight='active']")
        ?.setAttribute('data-highlight', 'remove');
    }

    const finalSelectedText = escapeHtml(selectedText);
    const spanElement = `<span style="background-color: ${AUTHORING_COLORS.NARRATIVE_SELECTION}" data-highlight="active">${finalSelectedText}</span>`;

    editorRef.current.execCommand('mceInsertContent', false, spanElement);
    setSelection(getElement(editorRef, clickedNodeId));
    if (selection) {
      removeHighlightedSpan(selection);
    }
  };

  const removeHighlightedSpan = (element: HTMLElement) => {
    const spanToRemove = element.querySelector("span[data-highlight='remove']");
    if (spanToRemove) {
      const parentElement =
        spanToRemove.parentElement === element ? element : spanToRemove.parentElement;
      if (parentElement) {
        while (spanToRemove.firstChild) {
          parentElement.insertBefore(spanToRemove.firstChild, spanToRemove);
        }
        spanToRemove.remove();
      }
    }
  };

  const resetActiveTextHighlight = (selection: HTMLElement | null | undefined) => {
    if (selection) {
      const activeSpan = selection.querySelector(
        "span[data-highlight='active']",
      ) as HTMLElement | null;
      if (activeSpan) {
        activeSpan.setAttribute('data-highlight', 'remove');
        removeHighlightedSpan(selection);
      }
    }
  };

  const resetSourceTextHighlight = () => {
    if (!sourceSelection) return;

    resetActiveTextHighlight(sourceSelection);
    setSourceSelection(null);
  };

  const resetAllActiveTextHighlights = () => {
    resetActiveTextHighlight(targetSelection);
    resetSourceTextHighlight();
    setTargetSelection(null);
  };

  return {
    templateSelectionOnClickHandler,
    sourceSelectionOnClickHandler,
    updateTextSelectionNode,
    linkTextSelectionNode,
    resetAllActiveTextHighlights,
    resetSourceTextHighlight,
    sourceSelection,
    targetSelection,
  };
};
