import { ISourceDocument } from '../../../types';

export enum TargetSaveState {
  UNCHANGED = 'unchanged',
  UNSAVED = 'unsaved',
  SAVED = 'saved',
  AUTOSAVED = 'autoSaved',
}

export enum AdditionalTargetSaveState {
  PROCESSING = 'processing',
  PROCESSED = 'processed',
}

export type AuthoringTargetSaveState = TargetSaveState | AdditionalTargetSaveState;

export interface IStartAuthoringProps {
  selectedRecords: number[];
  sessionId: number;
  openNonInteractiveDocumentModal: () => void;
  templateType: 'Target' | 'Automated' | 'External';
  closeAuthoringModal: () => void;
  nonInteractiveDocuments: ISourceDocument[];
}
