// helpers.ts

import { DocumentTabs } from '../../../../../types';
import { ItemType } from '../../../../../services/api/catalogItems/catalogItems.types';
import { CatalogItemsHash, SourceFileGroup } from '../../../../upload';
import { Projects } from '../../../../../services/api/types';

export const isDropdownDisabled = (
  selectedData: string[],
  activeTab: DocumentTabs | undefined,
  rowId: string,
): boolean => {
  return (
    selectedData.length === 0 ||
    ((activeTab === DocumentTabs.AUTOMATED || activeTab === DocumentTabs.TARGET) &&
      !selectedData.includes(rowId))
  );
};

export const formatCellData = (
  data: CatalogItemsHash,
  itemType: ItemType | null = null,
): string => {
  const filteredData = itemType ? data[itemType] || [] : (Object.values(data) || []).flat();
  return filteredData?.map((item: SourceFileGroup) => item.name).join(', ');
};

export const createOptionValues = (items: (SourceFileGroup | Projects)[] = []) => {
  return items.map((item) => ({
    label: item.name,
    value: item.id?.toString() || '',
  }));
};

export const disableConvert = (activeTab: string, documentStatus: string): boolean => {
  return activeTab === DocumentTabs.IN_PROGRESS && documentStatus === 'Ready to Author';
};
