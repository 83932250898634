import { FC, useState, FormEvent, useContext, ChangeEvent } from 'react';
import Input, { InputType } from '../elements/Input';
import Avatar from '../avatar/Avatar';
import { UserContext } from '../../contexts/UserContext';
import UserRoles from './UserRoles';
import { editUserData } from '../../services/apiCalls';
import { DBUser } from '../../types';
import { notifyError, notifySuccess } from '../../helpers/utils';
import useClientInfo from '../../hooks/useClientInfo';
import { updateCognitoUserAttributes } from '../../helpers/amplify';
import { useAuth } from '../../contexts/AuthContext';
import { USER_FORM_AVATAR_STYLING } from '../../constants/userForm';
import { useImageUploader } from '../../hooks/useImageUploader';
interface IEditFormProps {
  closeModal: () => void;
  getAllUsers?: () => void;
  user: DBUser;
}

interface EditUserFields {
  label?: string;
  labelClassName?: string;
  disabled?: boolean;
  tooltip?: string;
  type?: InputType;
  name: string;
  placeholder?: string;
  className?: string;
  dataTestId?: string;
  value?: string;
  options?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const inputList: EditUserFields[] = [
  {
    label: 'First Name',
    type: 'text',
    name: 'firstName',
    placeholder: 'Enter first name',
    className: 'block w-full',
  },
  {
    label: 'Last Name',
    type: 'text',
    name: 'lastName',
    placeholder: 'Enter last name',
    className: 'block w-full',
  },
  {
    label: 'Email',
    type: 'email',
    name: 'email',
    placeholder: 'Enter email address',
    className: 'block w-full',
  },
];

interface IUserFields {
  firstName?: string;
  lastName?: string;
  email: string;
  avatar?: string;
  name?: string;
}

const AWS_DEFAULT_ATTRIBUTES = [
  'email',
  'phone_number',
  'name',
  'given_name',
  'family_name',
  'preferred_username',
  'cognito:user_status',
];

const EditUserForm: FC<IEditFormProps> = ({ closeModal, getAllUsers = () => {}, user }) => {
  const [userRoles, setUserRoles] = useState(
    user.roles.map((role) => ({ value: role.name, label: role.name })) || [],
  );
  const { userObj, updateUserAttributes } = useContext(UserContext);
  const { firstName, lastName, email } = user;
  const { isAdmin } = useClientInfo();
  const { setPermissions } = useAuth();
  const [updatedUserData, setUpdatedUserData] = useState<IUserFields>({
    firstName,
    lastName,
    email,
  });

  const [errors, _setErrors] = useState<any>({});
  const [showLoader, setShowLoader] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUpdatedUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isCurrentUser = userObj?.email === email;

  const updateUserAttributesInCognito = async () => {
    const { firstName, lastName, ...userDataWithoutName } = updatedUserData;
    const name = `${firstName} ${lastName}`;

    const userAttributes = [
      ...Object.entries(userDataWithoutName).map(([key, value]) => {
        if (value === (userObj as any)[key]) {
          return null; // Skip unchanged attributes
        }
        if (!AWS_DEFAULT_ATTRIBUTES.includes(key)) {
          return { Name: `custom:${key}`, Value: value };
        } else {
          return { Name: key, Value: value };
        }
      }),
      { Name: 'name', Value: name }, // Include the 'name' attribute
      { Name: 'given_name', Value: firstName || '' }, // Include the 'given_name' attribute
      { Name: 'family_name', Value: lastName || '' }, // Include the 'family_name' attribute
    ];

    const filteredUserAttributes = userAttributes.filter(Boolean);
    const resp = await updateCognitoUserAttributes(filteredUserAttributes);
    const updatedUserObj = filteredUserAttributes.reduce((acc, curr) => {
      if (curr) {
        acc[curr.Name] = curr.Value;
        if (curr.Name === 'custom:avatar') {
          acc['avatar'] = curr.Value;
        }
      }
      return acc;
    }, {} as any);
    updatedUserObj.firstName = firstName;
    updatedUserObj.lastName = lastName;
    updateUserAttributes(updatedUserObj);
    return resp;
  };

  const handleUpdateUser = async (event: FormEvent) => {
    event.preventDefault();
    setShowLoader(true);
    const newRoles = userRoles.map((role) => role.value);
    try {
      if (isCurrentUser) {
        await updateUserAttributesInCognito();
        setPermissions(newRoles);
      }
      await editUserData({ ...updatedUserData, roles: newRoles });
      getAllUsers();
      notifySuccess('User Updated Successfully.');
    } catch (err: any) {
      notifyError(err.message);
      console.error(err.message);
    } finally {
      closeModal();
      setShowLoader(false);
    }
  };

  const updateAvatar = (imageUrl: string) => {
    setUpdatedUserData((prevData) => ({
      ...prevData,
      avatar: imageUrl,
    }));
  };

  const { imageUrl, uploadImageToS3 } = useImageUploader();
  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const imageFile = e.target.files?.[0];
    if (!imageFile) return;
    const userId = `${firstName[0]}${lastName}`.toLowerCase().replace(/\s/g, '');
    const fileName = `${userId}-avatar`;
    const response = await uploadImageToS3(imageFile, fileName);

    if (response && updateAvatar) {
      updateAvatar(response);
    }
  };

  return (
    <>
      <div className="flex justify-center relative m-auto mb-8">
        <Avatar
          handleImageUpload={isCurrentUser ? handleImageUpload : undefined}
          user={{
            firstName: user.firstName,
            lastName: user.lastName,
            avatar: isCurrentUser ? userObj.avatar : undefined,
          }}
          avatarStyling={USER_FORM_AVATAR_STYLING}
          imageUrl={imageUrl}
        />
      </div>
      <form onSubmit={handleUpdateUser}>
        <div className="h-60">
          {inputList.map((item, index) => (
            <span key={index} className={item.className} title={item.tooltip}>
              <label
                htmlFor={item.name}
                className={`${item.labelClassName || 'block'}  ${
                  errors[item.name] ? 'text-red-500' : 'text-gray-900'
                } mb-2 text-sm font-medium`}>
                {item.label}
              </label>
              <Input
                {...(item.type === 'number' ? { min: '0' } : {})}
                type={item.type}
                name={item.name}
                value={updatedUserData[item.name as keyof typeof updatedUserData] as string}
                placeholder={item.placeholder}
                className={item.className}
                options={item.options}
                onChange={handleInputChange}
                disabled={
                  item.name === 'userName' ||
                  !isCurrentUser ||
                  (item.name === 'email' && isCurrentUser)
                }
                dataTestId={`input-${item.name}`}
              />
              {errors && errors[item.name] && (
                <p className="text-red-500 text-sm">{errors[item.name]}</p>
              )}
            </span>
          ))}
          <label htmlFor="roles" className="block text-sm font-medium mb-2 text-gray-900">
            Roles
          </label>
          <UserRoles onChangeHandler={setUserRoles} selectedRoles={userRoles} disabled={!isAdmin} />
        </div>
        <div className="ml-32 mt-20 w-96 flex justify-between">
          <input
            type="button"
            value="Cancel"
            onClick={closeModal}
            title={'Edit Your Profile'}
            className="bg-slate-400 text-white font-medium rounded-lg text-sm px-5 py-2.5 w-40 text-center"
          />
          <input
            type="submit"
            disabled={showLoader}
            value="Submit"
            title={'Edit Your Profile'}
            className={`w-full text-white ${
              showLoader ? 'bg-gray-500' : 'bg-primary_bg_color'
            } font-medium rounded-lg text-sm px-5 py-2.5 w-40 text-center hover:${
              !showLoader && 'bg-secondary_bg_color'
            }`}
          />
        </div>
      </form>
    </>
  );
};

export default EditUserForm;
