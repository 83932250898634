/* eslint-disable react/display-name */
import { createColumnHelper } from '@tanstack/react-table';
import { DocumentTabs, ISourceDocument, MappingSessionRecord } from '../../../../../types';
import { getDate } from '../../../../../helpers/catalogHelpers';
import { TableHeader } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { CatalogSortParams, MappingType, SortOrderType } from '../../../../../services/api/types';
import { getUserFullName } from '../../../../../helpers/utils';
import { ItemType } from '../../../../../services/api/types';
import { formatCellData } from './helper';
import { CatalogItemsHash } from '../../../../upload';

export const generateColumnsByTab = (
  activeTab: DocumentTabs,
  handleColumnSort: (sort: SortOrderType, column: keyof CatalogSortParams) => void,
  sortedColumn: { column: string; order: SortOrderType },
) => {
  const catalogColumnHelper = createColumnHelper<ISourceDocument>();
  const mappingSessionColumnHelper = createColumnHelper<MappingSessionRecord>();

  const createTableHeader = (
    title: string,
    accessor = '',
    activeColumn = { column: '', order: 'ASC' as SortOrderType },
  ) => {
    return () => (
      <TableHeader
        ColumnName={accessor}
        Title={title}
        handleColumnSort={handleColumnSort as (sort: SortOrderType, column: string) => void}
        activeColumn={activeColumn}
      />
    );
  };

  const valueFormatter = (value: string) => {
    switch (value) {
      case MappingType.AUTHORING:
        return 'Manual';
      case MappingType.AI_AUTHORING:
        return 'Automated';
      default:
        return value;
    }
  };

  const CatalogItemTableCell = ({
    data,
    itemType,
  }: {
    data: CatalogItemsHash;
    itemType: ItemType;
  }) => {
    const values = formatCellData(data, itemType);
    return <div className="text-normal text-neutral-400">{values}</div>;
  };

  const columnsByTab: Record<DocumentTabs, any[]> = {
    [DocumentTabs.TARGET]: [
      catalogColumnHelper.accessor('id', {
        header: createTableHeader('ID', 'id', sortedColumn),
        cell: (info) => info.getValue(),
        size: 46,
      }),
      catalogColumnHelper.accessor('documentName', {
        header: createTableHeader('Document Name', 'documentName', sortedColumn),
        cell: (info) => info.getValue(),
        size: 450,
      }),
      catalogColumnHelper.accessor('catalogItems', {
        header: createTableHeader('CTD Section', 'ctdSection', sortedColumn),
        cell: (info) => (
          <CatalogItemTableCell data={info.getValue() || {}} itemType={ItemType.CTDSection} />
        ),
        size: 77,
      }),
      catalogColumnHelper.accessor('catalogItems', {
        header: createTableHeader('Region', 'region', sortedColumn),
        cell: (info) => (
          <CatalogItemTableCell data={info.getValue() || {}} itemType={ItemType.Region} />
        ),
        size: 77,
      }),
      catalogColumnHelper.accessor('version', {
        header: createTableHeader('Version', 'version', sortedColumn),
        cell: (info) => info.getValue(),
        size: 89,
      }),
      catalogColumnHelper.accessor('uploadedBy', {
        header: createTableHeader('Uploaded By', 'uploadedBy', sortedColumn),
        cell: (info) => getUserFullName(info.row.original.createdBy),
        size: 180,
      }),
    ],
    [DocumentTabs.EXTERNAL]: [
      catalogColumnHelper.accessor('id', {
        header: createTableHeader('ID', 'id', sortedColumn),
        cell: (info) => info.getValue(),
        size: 46,
      }),
      catalogColumnHelper.accessor('documentName', {
        header: createTableHeader('Document Name', 'documentName', sortedColumn),
        cell: (info) => info.getValue(),
        size: 450,
      }),
      catalogColumnHelper.accessor('catalogItems', {
        header: createTableHeader('CTD Section', 'ctdSection', sortedColumn),
        cell: (info) => (
          <CatalogItemTableCell data={info.getValue() || {}} itemType={ItemType.CTDSection} />
        ),
        size: 77,
      }),
      catalogColumnHelper.accessor('catalogItems', {
        header: createTableHeader('Region', 'region', sortedColumn),
        cell: (info) => (
          <CatalogItemTableCell data={info.getValue() || {}} itemType={ItemType.Region} />
        ),
        size: 77,
      }),
      catalogColumnHelper.accessor('version', {
        header: createTableHeader('Version', 'version', sortedColumn),
        cell: (info) => info.getValue(),
        size: 89,
      }),
      catalogColumnHelper.accessor('uploadedBy', {
        header: createTableHeader('Uploaded By', 'uploadedBy', sortedColumn),
        cell: (info) => getUserFullName(info.row.original.createdBy),
        size: 180,
      }),
    ],
    [DocumentTabs.SOURCE]: [
      catalogColumnHelper.accessor('id', {
        header: createTableHeader('ID', 'id', sortedColumn),
        cell: (info) => info.getValue(),
        size: 46,
      }),
      catalogColumnHelper.accessor('documentName', {
        header: createTableHeader('Document Name', 'documentName', sortedColumn),
        cell: (info) => info.getValue(),
        size: 450,
      }),
      catalogColumnHelper.accessor('version', {
        header: createTableHeader('Veeva Doc Version', 'version', sortedColumn),
        cell: (info) => info.getValue(),
        size: 77,
      }),
      catalogColumnHelper.accessor('catalogItems', {
        header: createTableHeader('Material'),
        cell: (info) => {
          return <CatalogItemTableCell data={info.getValue() || {}} itemType={ItemType.Material} />;
        },
        size: 139,
      }),
      catalogColumnHelper.accessor('catalogItems', {
        header: createTableHeader('Process Id'),
        cell: (info) => (
          <CatalogItemTableCell data={info.getValue() || {}} itemType={ItemType.ProcessID} />
        ),
        size: 139,
      }),
      catalogColumnHelper.accessor('catalogItems', {
        header: createTableHeader('DP Image'),
        cell: (info) => (
          <CatalogItemTableCell data={info.getValue() || {}} itemType={ItemType.DPImage} />
        ),
        size: 139,
      }),
      catalogColumnHelper.accessor('catalogItems', {
        header: createTableHeader('Site'),
        cell: (info) => (
          <CatalogItemTableCell data={info.getValue() || {}} itemType={ItemType.Site} />
        ),
        size: 180,
      }),
      catalogColumnHelper.accessor('documentNum', {
        header: createTableHeader('Veeva Doc No', 'documentNum', sortedColumn),
        cell: (info) => info.getValue(),
        size: 120,
      }),
      catalogColumnHelper.accessor('uploadedBy', {
        header: createTableHeader('Uploaded By', 'uploadedBy', sortedColumn),
        cell: (info) => getUserFullName(info.row.original.createdBy),
        size: 139,
      }),
    ],
    [DocumentTabs.COMPLETED]: [
      mappingSessionColumnHelper.accessor('id', {
        header: createTableHeader('ID', 'id', sortedColumn),
        cell: (info) => info.getValue(),
        size: 46,
      }),
      mappingSessionColumnHelper.accessor('title', {
        header: createTableHeader('Document name', 'title', sortedColumn),
        cell: (info) => info.getValue(),
        size: 450,
      }),
      mappingSessionColumnHelper.accessor('project', {
        header: createTableHeader('Project'),
        cell: (info) => info.getValue()?.name,
        size: 209,
      }),
      mappingSessionColumnHelper.accessor('mappingType', {
        header: createTableHeader('Type', 'mappingType', sortedColumn),
        cell: (info) => valueFormatter(info.getValue()),
        size: 209,
      }),
      catalogColumnHelper.accessor('catalogItems', {
        header: createTableHeader('Section', 'ctdSection', sortedColumn),
        cell: (info) => (
          <CatalogItemTableCell data={info.getValue() || {}} itemType={ItemType.CTDSection} />
        ),
        size: 77,
      }),
      mappingSessionColumnHelper.accessor('createdAt', {
        header: createTableHeader('Created On'),
        cell: (info) => {
          const createdAt = info.row.original.createdAt;
          return createdAt && getDate(createdAt);
        },
      }),
      mappingSessionColumnHelper.accessor('createdBy', {
        header: createTableHeader('Owner', 'createdBy', sortedColumn),
        cell: (info) => getUserFullName(info.row.original.createdBy),
        size: 209,
      }),
    ],
    [DocumentTabs.IN_PROGRESS]: [
      mappingSessionColumnHelper.accessor('id', {
        header: createTableHeader('ID', 'id', sortedColumn),
        cell: (info) => info.getValue(),
        size: 46,
      }),
      mappingSessionColumnHelper.accessor('title', {
        header: createTableHeader('Document Name', 'title', sortedColumn),
        cell: (info) => info.getValue(),
        size: 450,
      }),
      mappingSessionColumnHelper.accessor('project', {
        header: createTableHeader('Project'),
        cell: (info) => info.getValue()?.name,
        size: 210,
      }),
      mappingSessionColumnHelper.accessor('mappingType', {
        header: createTableHeader('Type', 'mappingType', sortedColumn),
        cell: (info) => valueFormatter(info.getValue()),
        size: 120,
      }),
      mappingSessionColumnHelper.accessor('catalogItems', {
        header: createTableHeader('CTD Section', 'ctdSections', sortedColumn),
        cell: (info) => (
          <CatalogItemTableCell data={info.getValue() || {}} itemType={ItemType.CTDSection} />
        ),
        size: 79,
      }),
      mappingSessionColumnHelper.accessor('status', {
        header: createTableHeader('Status', 'status', sortedColumn),
        cell: (info) => info.getValue(),
        size: 235,
      }),
      mappingSessionColumnHelper.accessor('updatedAt', {
        header: createTableHeader('Last Modified', 'updatedAt', sortedColumn),
        cell: (info) => {
          const updatedAt = info.row.original.updatedAt;
          return updatedAt && getDate(updatedAt);
        },
        size: 130,
      }),
      mappingSessionColumnHelper.accessor('createdBy', {
        header: createTableHeader('Owner', 'ownedBy', sortedColumn),
        cell: (info) => getUserFullName(info.row.original.createdBy),
        size: 116,
      }),
    ],
    [DocumentTabs.AUTOMATED]: [
      catalogColumnHelper.accessor('id', {
        header: createTableHeader('ID', 'id', sortedColumn),
        cell: (info) => info.getValue(),
        size: 46,
      }),
      catalogColumnHelper.accessor('title', {
        header: createTableHeader('Document Name', 'title', sortedColumn),
        cell: (info) => info.getValue(),
        size: 450,
      }),
      catalogColumnHelper.accessor('catalogItems', {
        header: createTableHeader('CTD Section', 'catalogItems'),
        cell: (info) => (
          <CatalogItemTableCell data={info.getValue() || {}} itemType={ItemType.CTDSection} />
        ),
        size: 77,
      }),
      catalogColumnHelper.accessor('catalogItems', {
        header: createTableHeader('Region', 'region', sortedColumn),
        cell: (info) => (
          <CatalogItemTableCell data={info.getValue() || {}} itemType={ItemType.Region} />
        ),
        size: 77,
      }),
      catalogColumnHelper.accessor('version', {
        header: createTableHeader('Version', 'version', sortedColumn),
        cell: (info) => info.getValue(),
        size: 89,
      }),
      catalogColumnHelper.accessor('uploadedBy', {
        header: createTableHeader('Uploaded By', 'uploadedBy', sortedColumn),
        cell: (info) => getUserFullName(info.row.original.createdBy),
        size: 180,
      }),
    ],
    [DocumentTabs.PRE_AUTHORED]: [],
  };

  return columnsByTab[activeTab];
};
