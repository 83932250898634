import { z } from 'zod';
import { versionSchema } from '../../addSource/utils/schema';

export const automatedSchema = z.object({
  templateName: z.string().min(1),
  materials: z.array(z.object({ label: z.string(), value: z.string() })).optional(),
  ctdSections: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'CTD Section must have at least one element' }),
  version: versionSchema,
});

export const completedSchema = z.object({
  templateName: z.string().min(1),
  materials: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'Material must have at least one element' }),
  ctdSections: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'CTD Section must have at least one element' }),
  version: versionSchema,
});

export const targetSchema = z.object({
  ctdSections: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'CTD Section must have at least one element' }),
});

export const sourceSchema = z.object({
  materials: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'Materials must have at least one element' }),
});

export const inProgressSchema = z.object({
  title: z.string().min(1),
  project: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'Project must have at least one element' })
    .max(1, { message: 'Project must have at most one element' }),
  ctdSections: z
    .array(z.object({ label: z.string(), value: z.string() }))
    .min(1, { message: 'CTD Section must have at least one element' }),
});

export const catalogItemSlideOverSchema = z.object({
  templateName: z.string().min(1),
  material: z
    .array(z.object({ name: z.string(), id: z.number() }))
    .min(1, { message: 'Material must have at least one element' }),
  ctdSections: z
    .array(z.object({ name: z.string(), id: z.number() }))
    .min(1, { message: 'CTD Section must have at least one element' }),
  version: versionSchema,
});

export const templateUploadSchema = z.object({
  templateName: z.string().min(1),
  ctdSections: z
    .array(z.object({ name: z.string(), id: z.number() }))
    .min(1, { message: 'CTD Section must have at least one element' }),
  version: versionSchema,
  regions: z
    .array(z.object({ name: z.string(), id: z.number() }))
    .min(1, { message: 'Region must have at least one element' }),
});
