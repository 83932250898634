import {
  CatalogFilterParams,
  CatalogSortParams,
  Filter,
  GetCatalogFileDataParams,
  MessageResponse,
  PaginationFilteredParams,
  PaginationResponse,
  Projects,
  User,
} from '../types';
import { AIResponse, AutowriteRequestData, IGroupingVariables } from '../../../types';
import { CatalogItemsHash } from '../../../pages/upload';

export enum MappingType {
  AUTO_MAPPING = 'auto-mapping',
  MAPPING = 'mapping',
  AI_AUTHORING = 'ai-authoring',
  AUTHORING = 'authoring',
  EXTERNAL = 'external',
}

export enum StatusType {
  GENERATING_AI_SUGGESTIONS = 'Generating AI Suggestions',
  SUGGESTIONS_FAILED = 'Suggestions Failed',
  READY_TO_AUTHOR = 'Ready to Author',
  AUTHORING_IN_PROGRESS = 'Authoring In-Progress',
  READY_TO_REVIEW = 'Ready to Review',
  VERIFICATION_IN_PROGRESS = 'Verification In-Progress',
  AUTHOR_REVIEW = 'Author Review',
  COMPLETED = 'Completed',
}

export type AutowriteStatusType = 'pending' | 'complete' | 'failed';

export type MappingSession = {
  id: number;
  title: string;
  documentTitle?: string;
  version: number;
  ctdSections: string;
  program?: string;
  detailedNotes?: string;
  contentSpecifics?: string;
  totalSuggestionsCount: number;
  selectedSuggestionsCount: number;
  createdAt: string;
  createdBy: User;
  createdById: number;
  sourceFileIds?: number[] | null;
  mappingType: MappingType;
  status: StatusType;
  autowriteStatus: AutowriteStatusType;
  project: Pick<Projects, 'id' | 'name'>;
  catalogItems: CatalogItemsHash;
};

export type MappingSessionFilterParams = Partial<
  Pick<MappingSession, 'createdById' | 'ctdSections' | 'mappingType' | 'status' | 'program'> & {
    projectId: number;
  }
>;

export type MappingSessionSortParams = Partial<
  Pick<
    MappingSession,
    'documentTitle' | 'ctdSections' | 'status' | 'createdAt' | 'createdById' | 'id'
  >
>;

export type GetMappingSessionParams = PaginationFilteredParams<
  MappingSessionFilterParams,
  MappingSessionSortParams
>;

export type GetMappingSessionResponse<T> = PaginationResponse<T>;

export type GetMappingSessionsFilterOptionsResponse = {
  ctdSections: Filter;
  regions: Filter;
  createdById: Filter;
  status: Filter;
  materials: Filter;
  mappingType: Filter;
  version: Filter;
  projects: Filter;
};

export type UpdateMappingSessionStatusParams = { status: string; id: number };
export type UpdateMappingSessionStatusResponse = MessageResponse;

export type AddSourceFileParams = { sourceFileIds: number[]; sessionId: number };
export type AddSourceFileResponse = {
  filename: string;
  id: number;
};

export type RemoveSourceFilesFromSessionParams = { catalogIds: number[]; sessionId: number };
export type RemoveSourceFilesFromSessionResponse = MessageResponse;

export type ManualAuthorParams = GetCatalogFileDataParams;
export type ManualAuthorResponse = { id: number };

export type AutowriteParams = AutowriteRequestData;
export type AutowriteResponse = MessageResponse;
export type UpdateMappingSessionParams = Partial<
  Pick<
    MappingSession,
    'id' | 'title' | 'documentTitle' | 'contentSpecifics' | 'program' | 'version' | 'detailedNotes'
  > & {
    material?: string;
    ctdSection?: string;
  }
>;

export type UpdateMappingSessionResponse = MessageResponse;
export type GetMappingSessionSourceFilesParams = PaginationFilteredParams<
  CatalogFilterParams,
  CatalogSortParams
>;

export type DynamicMappingParams = {
  sessionId: number;
  newTargetNodeId: string;
  suggestionId: number;
  sourceIds: number[];
};

export type DynamicMappingResponse = {
  message: string;
  groupingVariables: IGroupingVariables;
  aiSuggestions: AIResponse;
};

export type AutoAcceptSuggestionParam = {
  id: number;
};

export type ArchiveMappingSessionResponse = MessageResponse;

export type CopyMappingSessionParams = {
  id: number;
  title: string;
  projectId: number;
  ctdSectionIds: number[];
};

export type CopyMappingSessionResponse = MessageResponse & { copiedSession: MappingSession };

export type MappingSessionDetailsResponse = {
  createdBy: string;
  createdAt: string;
  lastUpdatedAt: string;
  authors: string[];
  validators: string[];
  title: string;
  totalSuggestionsCount: number;
  sourceFileIds: number[];
  autowriteStatus: string;
  mappingType: string;
};
