// React-related imports
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Contexts and Hooks
import { MappingContext } from '../../contexts/MappingContext';
import { useGeneralizationAuthoring } from './hooks/useAuthoring';
import { GeneralizationContext } from '../../contexts/GeneralizationContext';
import { useSourceFiles } from '../../hooks/useSourceFiles';
import { useQuickFill } from '../../hooks/generalizationHooks/useQuickFill';
import useFullscreen from '../../hooks/useFullscreen';
import useModal from '../../hooks/useModal';

// Components
import LoadingOverlay from '../../components/elements/LoadingOverlay';
import SuggestionNavigateButtons from '../../components/SuggestionNavigateButtons';
import TinyEditor from '../../components/TinyEditor';
import FileNameModal from '../../components/modals/FileNameModal';
import { ImageTagging } from './components/ImageTagging';
import Layout from '../../components/layout';
import AuthoringHeader from './components/AuthoringHeader/AuthoringHeader';
import InfoPanel from '../../components/InfoPanel/InfoPanel';
import AuthoringActions from './components/AuthoringActions/AuthoringActions';
import SourcesActions from '../../components/SourceActions/SourceActions';
import QuickFillModal from '../../components/modals/QuickFillModal';
import CommentsSlideOver from './components/CommentsSlideOver/CommentsSlideOver';
import { Button, Dropdown, Switch } from '@gloabal-regulatory-writing-consulting/gxt-components';
import SaveChangesModal from '../../components/modals/SaveChangesModal';
import { DeleteConfirmationDialogue } from '../../components/modals/DeleteConfirmationDialogue';

// Helpers
import {
  deepCopyDocument,
  handleEnterKeyPress,
  isCopyColumnOrRowCommand,
  setAttributesAndOrder,
  getElementsByQuery,
  handleAcceptButtonClick,
  updateNewNodeAttributes,
  getPreviousNode,
} from '../../helpers/generalizationHelpers';
import { handleDownload } from '../../helpers/convertFileType';

// Constants
import { LIGHT_BLUE_COLOR, SearchThreshold } from '../../constants';

// Types
import {
  EditorEvent,
  Node as customNode,
  SearchOptions,
  SearchResult,
  EditorTypeEnum,
  AIResponse,
  EditorRef,
  KeyboardEventWithShift,
  MappingTypeEnum,
  AcceptanceMethodEnum,
} from '../../types';
import { AdditionalTargetSaveState, TargetSaveState } from './hooks/types';
import { OptionType } from './types';

// APIs
import { search } from '../../services/apiCalls';

//Constants
import { actionOptions, exportOptions, groupedOptions } from './utils/constants/actions';
import { AddSourcesPreviousAction } from '../catalog/util/constants';

// Other dependencies
import { Editor as TinyMCEEditor } from 'tinymce';
import _ from 'lodash';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import NewSuggestionModal from './components/modals/NewSuggestionModal';
import { useMutationState } from '@tanstack/react-query';
import FindFigureModal from './components/modals/FindFigureModal';

const Authoring = () => {
  const { editor1Ref, editor2Ref, targetId, mappingComments } = useContext(MappingContext);
  const { toggleFullScreen } = useFullscreen();

  const {
    generatedDocument,
    loadingSourceDocument,
    sourceDocuments,
    selectedCatalogId,
    sessionId,
    mappingType,
    setSearchTerm,
    setAiResponse,
    lockSourceFile,
    gvCategories,
    aiResponse,
    targetNodeIdSuggestionMap,
    resetStates,
    lastSaved,
    authors,
    isFullScreen,
    setInitialTarget,
    imageSuggestions,
    imageSuggestionsFiles,
    sessionStatus,
  } = useContext(GeneralizationContext);

  const {
    isLoading,
    highlightAllTargetSuggestions,
    highlightAllSourceDocumentSuggestions,
    receiveSuggestions,
    // isUndoAvailable,
    // handleGeneralizationAuthoringUndo,
    targetOnClickHandler,
    sourceDocumentOnClickHandler,
    aiSuggestionList,
    isDisabledApprove,
    handleKeyDown,
    handleKeyUp,
    setSearchResult,
    setIsSearched,
    disableLinkButton,
    handleLinkNodes,
    isNextAuthoringCellAvailable,
    isPreviousAuthoringCellAvailable,
    navigateToAuthoringCell,
    getCurrentNodeId,
    handleReset,
    isSnappingEnabled,
    updatedAiResponse,
    setCopiedAIResponse,
    handleTargetNodeIds,
    handleLockedFileChange,
    isSavingSuggestions,
    currentTargetNodeIds,
    isHeaderCellSelected,
    callSaveSuggestions,
    setColors,
    isSaving,
    handleSave,
    handleAiDropDownChange,
    suggestionsWithColors,
    toggleImageTagging,
    handleClosePinningSlideOver,
    isTaggingSlideOverOpen,
    handleSubmitTag,
    imageTagFormValues,
    targetChangesStatus,
    openCommentModalWithThread,
    commentModal,
    displayCommentButton,
    isNewSuggestionsActive,
    setIsNewSuggestionsActive,
    setTargetChangesStatus,
    autoFillHandler,
    isImageNodeSelected,
    handleSourceNodeIds,
  } = useGeneralizationAuthoring();

  const {
    selectedOption,
    handleOptionChange,
    clearSelection,
    handleApplyFilter,
    isQuickFillModalOpen,
    closeQuickFillModal,
    openQuickFillModal,
    isSavingFilledSuggestions,
  } = useQuickFill({
    currentTargetNodeIds: currentTargetNodeIds,
    getCurrentNodeId,
    updatedAiResponse,
    callSaveSuggestions,
    setInitialTarget,
    editor1Ref,
    aiResponse,
    sessionId,
    targetNodeIdSuggestionMap,
  });

  const {
    showSourceDeleteModal,
    handleRemoveSourceFile,
    handleFileRemoveModalClose,
    sourceDocsArray,
    sourceFilesDropdown,
    lockedFileSwitch,
    handleSourceToggle,
    currentFileVersion,
  } = useSourceFiles({ handleLockedFileChange });

  const {
    show: showSaveChangesModal,
    closeModal: closeSaveChangesModal,
    openModal: openSaveChangesModal,
  } = useModal();
  const {
    show: showCommentsSlideOver,
    closeModal: closeCommentsSlideOver,
    openModal: openCommentsSlideOver,
  } = useModal();
  const {
    show: newSuggestionsModal,
    closeModal: closeNewSuggestionsModal,
    openModal: openNewSuggestionsModal,
  } = useModal();

  const {
    show: isFindFigureModalOpen,
    closeModal: closeFindFigureModal,
    openModal: openFindFigureModal,
  } = useModal();

  const navigate = useNavigate();
  const location = useLocation();
  const { targetNodeId } = location.state || {};

  const [searchOptions, setSearchOptions] = useState<SearchOptions[]>([]);
  const [resetPlaceholder, setResetPlaceholder] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const updatedAiResponseRef = useRef<AIResponse | null>();
  const acceptButtonRef = useRef<HTMLButtonElement>(null);
  const undoButtonRef = useRef<HTMLButtonElement>(null);
  const generatedDocumentRef = useRef<typeof generatedDocument | null>(generatedDocument);
  const isHeaderCellSelectedRef = useRef<boolean>(false);
  const mappingTypeRef = useRef<MappingTypeEnum | null>();

  const dynamicSuggestionsStatusData = useMutationState({
    filters: { mutationKey: ['dynamicMappingSuggestions'] },
    select: (mutation) => mutation.state.status,
  });

  useEffect(() => {
    if (dynamicSuggestionsStatusData.includes('pending')) {
      setTargetChangesStatus(AdditionalTargetSaveState.PROCESSING);
    } else if (dynamicSuggestionsStatusData.length) {
      setTargetChangesStatus(AdditionalTargetSaveState.PROCESSED);
    }
  }, [dynamicSuggestionsStatusData]);

  useEffect(() => {
    if (targetNodeId) {
      handleTargetNodeIds([targetNodeId]);
    }
  }, [targetNodeId]);

  const [newSuggestionsVisited, setNewSuggestionsVisited] = useLocalStorage(
    'IsNewSuggestionsVisited',
    false,
  );

  const isAutoMapping = mappingType === MappingTypeEnum.AUTO_MAPPING;
  const isAiAuthoring = mappingType === MappingTypeEnum.AI_AUTHORING;

  useEffect(() => {
    generatedDocumentRef.current = generatedDocument;
    mappingTypeRef.current = mappingType;
    updatedAiResponseRef.current = updatedAiResponse;
    isHeaderCellSelectedRef.current = isHeaderCellSelected;

    return () => {
      generatedDocumentRef.current = null;
      mappingTypeRef.current = null;
      updatedAiResponseRef.current = null;
      isHeaderCellSelectedRef.current = false;
    };
  }, [generatedDocument, mappingType, updatedAiResponse, isHeaderCellSelected]);

  useEffect(() => {
    return () => {
      resetStates();
    };
  }, []);

  const handleKeydown = (
    e: KeyboardEvent,
    acceptButtonRef: React.RefObject<HTMLButtonElement>,
    editorRef: EditorRef,
    isSource = false,
  ) => {
    if (isSource) {
      if (e.shiftKey && e.key === 'Enter') handleAcceptButtonClick(e, acceptButtonRef);
    } else {
      handleEnterKeyPress(e, acceptButtonRef, editorRef);
    }

    customUndoCallback(e);
    handleKeyDown(e);
    if (isSource) e.preventDefault();
  };

  const acceptSuggestionHandler = () => {
    receiveSuggestions(undefined, AcceptanceMethodEnum.AUTHOR).then(() => {
      if (isSnappingEnabled) navigateCells('next');
    });
  };

  const handleExecCommand = (
    e: EditorEvent,
    editor: TinyMCEEditor | null,
    generatedDocumentRef: React.RefObject<typeof generatedDocument>,
  ) => {
    if (!editor?.iframeElement) return;
    const iframe = editor?.iframeElement;
    const iframeDoc = iframe?.contentDocument || iframe?.contentWindow?.document;
    if (iframeDoc && generatedDocumentRef?.current?.id) {
      const nodes = iframeDoc.body.querySelectorAll<HTMLElement>(
        ":not([data-nodeid]):not(br):not(span[data-highlight]):not(tbody):not([data-mce-bogus='all']):not([data-comment='true'])",
      );
      const generatedDocumentId = generatedDocumentRef?.current?.id.toString();
      updateNewNodeAttributes(Array.from(nodes), generatedDocumentId);

      // Array.from(nodes).forEach((node) => {
      //   const matchingElements = node.querySelectorAll('[data-nodeid]:not([data-newnode])');
      //   (Array.from(matchingElements) as HTMLElement[]).forEach((ele: HTMLElement) => ele.remove());
      // });
      if (
        isCopyColumnOrRowCommand(nodes) &&
        mappingTypeRef.current === MappingTypeEnum.AI_AUTHORING
      ) {
        const nodeName = nodes[0].nodeName as 'TH' | 'TR';
        copySuggestionsToNextColumn(nodes, nodeName);
      }
      if (nodes.length === 1 && nodes[0].getAttribute('data-new-paragraph')) {
        const newParagraphNodeId = nodes[0].getAttribute('data-nodeid');
        newParagraphNodeId && handleTargetNodeIds([newParagraphNodeId]);
      }
    }
    return;
  };

  const copySuggestionsToNextColumn = (nodes: NodeListOf<Element>, nodeName: 'TH' | 'TR') => {
    const copiedSuggestions: AIResponse = Array.from(nodes).reduce(
      (acc: AIResponse, node: Element) => {
        const previousNode = getPreviousNode(node, nodeName);
        const previousNodeId = previousNode?.getAttribute('data-nodeid');
        const nodeId = node.getAttribute('data-nodeid');
        if (nodeId && previousNodeId && updatedAiResponseRef && updatedAiResponseRef.current) {
          const suggestedNodeId = getCurrentNodeId(
            previousNodeId,
            editor1Ref,
            updatedAiResponseRef.current,
          );

          if (suggestedNodeId && updatedAiResponseRef.current[suggestedNodeId]) {
            return { ...acc, [nodeId]: updatedAiResponseRef.current[suggestedNodeId] };
          }
        }

        return acc;
      },
      {},
    );
    setAiResponse((prev) => ({ ...prev, ...copiedSuggestions }));
    setCopiedAIResponse((prev) => ({ ...prev, ...copiedSuggestions }));
  };

  const options = (suggestionList: Array<{ [key: string]: any }>, status: string) => {
    const aiSuggestions: SearchOptions[] = suggestionList?.map((s, i) => {
      const suggestions: any = _.get(_.values(s), '[0]');
      const filename = sourceDocuments?.get(suggestions ? suggestions.sourceFileId?.toString() : '')
        ?.filename;

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = suggestions?.value;
      return {
        label: (
          <>
            <p
              className={`text-sm font-semibold ${
                status === 'locked' ? 'text-blue-900' : 'text-blue-600'
              }`}>
              {tempDiv.textContent}
            </p>
            <p className="text-xs text-gray-500">{filename}</p>
            {suggestions.groupingVariables?.map((gv: string[], i: number) => (
              <p className="text-xs text-blue-400" key={i}>
                {gv}
              </p>
            ))}
          </>
        ),
        value: i,
        nodeId: Object.keys(s)[0],
        order: suggestions?.order,
      };
    });
    return aiSuggestions;
  };

  const suggestionOptions = useMemo(() => {
    setSearchTerm('');

    const lockedAiSuggestionsList: { [key: string]: any }[] = [];
    const unlockedAiSuggestionsList: { [key: string]: any }[] = [];

    aiSuggestionList?.forEach((item) => {
      const sourceFileId = Object.values(item)[0].sourceFileId;
      sourceFileId === selectedCatalogId
        ? lockedAiSuggestionsList.push(item)
        : unlockedAiSuggestionsList.push(item);
    });

    const aiLockedSuggestions = lockedAiSuggestionsList.length
      ? options(lockedAiSuggestionsList, 'locked')
      : [];
    if (lockSourceFile) {
      return [...aiLockedSuggestions];
    } else {
      const aiUnlockedSuggestions = unlockedAiSuggestionsList.length
        ? options(unlockedAiSuggestionsList, 'unlocked')
        : [];
      const availableSuggestions = [...aiLockedSuggestions, ...aiUnlockedSuggestions];

      availableSuggestions.sort((a: any, b: any) => {
        return (a.order || 0) - (b.order || 0);
      });

      return availableSuggestions;
    }
  }, [aiSuggestionList, sourceDocuments, lockSourceFile, selectedCatalogId]);

  const customTextOnSelect = (node: customNode): string => {
    if (
      node &&
      node.props &&
      Array.isArray(node.props.children) &&
      node.props.children[0] &&
      node.props.children[0].props
    ) {
      return node.props.children[0].props.children.toString();
    }
    return '';
  };

  const navigateCells = (direction: 'next' | 'previous') => {
    removeSearchedHighlight();
    const nodeId = navigateToAuthoringCell(direction);
    if (nodeId) handleTargetNodeIds([getCurrentNodeId(nodeId)]);
  };

  const removeSearchedHighlight = () => {
    const prevEl = getElementsByQuery(editor2Ref, '.searched-source-node');
    if (prevEl && prevEl.length > 0) {
      (prevEl[0] as HTMLElement).classList.remove('searched-source-node');
      (prevEl[0] as HTMLElement).style.backgroundColor = '';
    }
  };

  useEffect(() => {
    setResetPlaceholder(true);
  }, [lockSourceFile, selectedCatalogId]);

  const handleSearchTerm = useCallback(
    async (term: string) => {
      let searchResponse: SearchResult[] = [];
      if (term) {
        const dataDocIds = lockSourceFile
          ? [+(sourceDocsArray.find((doc) => +doc.value === selectedCatalogId)?.value || 0)]
          : sourceDocsArray.map((doc) => +doc.value);

        searchResponse = await search({
          searchTerm: term,
          searchThreshold: SearchThreshold,
          dataDocIds: dataDocIds,
        }).then((result) => result.data.data);
      }
      if (!searchResponse.length || !term) {
        const results = [
          {
            label: (
              <>
                <p className="text-sm font-semibold  text-gray-500">No Result Found</p>
              </>
            ),
            value: -1,
          },
        ];
        setSearchOptions(results);
      } else {
        setSearchResult(searchResponse);
        const options: SearchOptions[] = searchResponse?.map((result: SearchResult, i: number) => {
          return {
            label: (
              <div onClick={() => setIsSearched(true)}>
                <p className="text-sm font-semibold">{result.dataText}</p>
                <p className="text-xs text-gray-500">{result.fileTitle}</p>
              </div>
            ),
            value: i,
            nodeId: result.dataNodeId,
          };
        });
        setSearchOptions(options);
      }
    },
    [sourceDocuments, targetId],
  );

  const customUndoCallback = (e: KeyboardEvent) => {
    const isUndo = (e.ctrlKey || e.metaKey) && e.key === 'z';
    if (isUndo) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      undoButtonRef?.current?.click();
    }
  };

  const scrollToFirstHighlight = (editorRef: RefObject<EditorRef>) => {
    const editorElement = editorRef.current?.getDoc();
    const partialSelectionActiveSpan = editorElement?.querySelector('[data-highlight="active"]');

    if (editorElement && !partialSelectionActiveSpan) {
      const highlightedElement: HTMLElement | null = editorElement.querySelector(
        '[data-highlighted="true"]',
      );
      if (highlightedElement) {
        const eleNodeId = highlightedElement.getAttribute('data-nodeid');
        const currentNodeId = eleNodeId && getCurrentNodeId(eleNodeId, editorRef);
        if (!currentTargetNodeIds.length && currentNodeId) {
          handleTargetNodeIds([currentNodeId]);
          setColors([LIGHT_BLUE_COLOR]);
        }
      }
    }
  };

  useEffect(() => {
    setSearchTerm('');
    const handleCustomUndo = (e: KeyboardEvent) => customUndoCallback(e);
    const handleEnterKey = (e: KeyboardEvent) => handleEnterKeyPress(e, acceptButtonRef);

    document.addEventListener('keypress', handleCustomUndo);
    document.addEventListener('keypress', handleEnterKey);

    return () => {
      document.removeEventListener('keypress', handleCustomUndo);
      document.removeEventListener('keypress', handleEnterKey);
    };
  }, []);

  const handleNewNodesOnEnterKeyPress = (e: KeyboardEvent | EditorEvent, editor: any) => {
    const { key, shiftKey } = e as KeyboardEvent;
    const generatedDocumentId = generatedDocumentRef?.current?.id?.toString();

    if (key === 'Enter' && !shiftKey && generatedDocumentId) {
      const currentAnchorNode = editor.selection.getSel()?.anchorNode as HTMLElement | null;
      const currentSelection =
        currentAnchorNode?.nodeName === '#text'
          ? (currentAnchorNode?.parentNode as HTMLElement | null)
          : currentAnchorNode;

      if (currentSelection && currentSelection.nodeName !== '#text') {
        const selectionNodeId = currentSelection?.getAttribute('data-nodeid');
        let nodes = null;

        // selectionNodeId can be null for new paragraphs inserted by Tinymce on enter/return
        if (selectionNodeId) {
          nodes = editor.getDoc().querySelectorAll(`[data-nodeid='${selectionNodeId}']`);
        }

        // If a new node is added with the duplicate attributes including data-nodeid, we can have more than one node
        if ((nodes && nodes.length > 1) || !selectionNodeId) {
          currentSelection.setAttribute('data-newnode', 'true');
          setAttributesAndOrder(currentSelection, generatedDocumentId, true);

          if (currentSelection.getAttribute('data-newnode')) {
            const newNodeId = currentSelection.getAttribute('data-nodeid');
            newNodeId && handleTargetNodeIds([newNodeId]);
          }
        }
      }
    }
  };

  const targetEventHandlers: {
    event: string;
    handler: (e: KeyboardEvent | EditorEvent | any, editor?: any) => void;
  }[] = [
    {
      event: 'keydown',
      handler: (e: KeyboardEvent | EditorEvent, editor: any) => {
        handleNewNodesOnEnterKeyPress(e, editor);
        handleKeydown(e as KeyboardEvent, acceptButtonRef, editor);
      },
    },
    {
      event: 'keyup',
      handler: (e: KeyboardEvent | EditorEvent, editor: TinyMCEEditor) => {
        handleNewNodesOnEnterKeyPress(e, editor);
        handleKeyUp(e as KeyboardEvent);
      },
    },
    {
      event: 'ExecCommand',
      handler: (e: KeyboardEvent | EditorEvent | any, editor: any) =>
        handleExecCommand(e as EditorEvent, editor, generatedDocumentRef),
    },
    {
      event: 'keydown',
      handler: (e: KeyboardEventWithShift, _editor: any) => {
        if ((e.metaKey || e.ctrlKey) && e.key === 'g' && isHeaderCellSelectedRef.current) {
          openQuickFillModal();
        }
      },
    },
  ];

  const exportDocument = (annotated = false) => {
    handleDownload(
      editor1Ref?.current?.getBody()?.innerHTML,
      generatedDocument?.filename,
      suggestionsWithColors(),
      mappingComments,
      annotated,
    );
  };

  const handleClose = () => {
    if (targetChangesStatus === 'unsaved') openSaveChangesModal();
    else navigate('/catalog');
  };

  const handleAuthoringSaveChanges = () => {
    handleSave(undefined, AcceptanceMethodEnum.AUTHOR);
    navigate('/catalog');
  };

  const actionHandlers = {
    [actionOptions.quickFill.value]: openQuickFillModal,
    [actionOptions.link.value]: handleLinkNodes,
    [actionOptions.comment.value]: () => openCommentModalWithThread(currentTargetNodeIds[0]),
    [actionOptions.viewComments.value]: openCommentsSlideOver,
    [actionOptions.imageTagging.value]: toggleImageTagging,
    [actionOptions.reset.value]: handleReset,
    [exportOptions.cleanDocument.value]: () => exportDocument(false),
    [exportOptions.annotatedDocument.value]: () => exportDocument(true),
    [actionOptions.autoFill.value]: autoFillHandler,
    [actionOptions.findFigure.value]: () => openFindFigureModal(),
  };

  const handleActionSelect = (option: OptionType) => {
    const handler = actionHandlers[option];
    if (handler) {
      handler();
    } else {
      console.error('Unknown option selected:', option);
    }
  };

  const handleModeChange = useCallback(
    (option: string) => {
      if (option === 'Editing Mode') {
        setEditMode(true);
        !editMode && handleReset();
      } else {
        setEditMode(false);
      }
    },
    [setEditMode, handleReset, editMode],
  );
  useEffect(() => {
    const handleBeforeUnload = (event: { preventDefault: () => void; returnValue: string }) => {
      if (targetChangesStatus === TargetSaveState.UNSAVED) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [targetChangesStatus]);

  return (
    <>
      <Layout>
        <Layout.Header>
          <AuthoringHeader
            isAutoMapping={isAutoMapping}
            isLoading={isSaving || isSavingSuggestions}
            handleSave={() => handleSave(undefined, AcceptanceMethodEnum.MANUAL)}
            handleClose={handleClose}
          />
        </Layout.Header>
        <Layout.Body>
          {isLoading ? (
            <LoadingOverlay />
          ) : (
            <div className="w-full flex flex-col flex-grow ">
              {!isFullScreen && (
                <InfoPanel
                  lastSaved={lastSaved}
                  badges={[
                    { label: 'Authored By', values: authors },
                    { label: 'Status', values: [sessionStatus] },
                  ]}
                />
              )}
              <div className={`flex flex-col flex-grow`}>
                <AuthoringActions
                  suggestionOptions={suggestionOptions}
                  searchOptions={searchOptions}
                  isFullScreen={isFullScreen}
                  handleFullScreen={toggleFullScreen}
                  handleSearchTerm={handleSearchTerm}
                  customTextOnSelect={customTextOnSelect}
                  handleAiDropDownChange={handleAiDropDownChange}
                  targetChangesStatus={targetChangesStatus}
                  resetPlaceholder={resetPlaceholder}
                  setResetPlaceholder={setResetPlaceholder}
                  onModeChange={handleModeChange}
                  editMode={editMode}
                />
                {loadingSourceDocument && <LoadingOverlay />}
                <div className="w-full flex justify-between items-center space-x-5">
                  <div className="w-full">
                    <SourcesActions
                      navigateAddSource={() => {
                        navigate('/add-source', {
                          state: {
                            sessionId,
                            previousAction: AddSourcesPreviousAction.ADD_SOURCES,
                          },
                        });
                      }}
                      sourceFilesDropdown={sourceFilesDropdown}
                      lockedFileSwitch={lockedFileSwitch}
                      displayLockFileButton={true}
                      handleSourceToggle={handleSourceToggle}
                      currentSourceVersion={currentFileVersion}
                    />
                  </div>

                  <div className="w-full flex flex-col">
                    <div className="flex justify-start space-x-2">
                      <h2 className="text-primary-300 mb-2">Target:</h2>
                      <h3 className="text-primary-300 font-normal">
                        {generatedDocument?.filename}
                      </h3>
                    </div>
                    <div className="flex items-center space-x-2 justify-between">
                      <div className="flex items-center space-x-2 whitespace-nowrap">
                        <Dropdown
                          customStyles={{
                            placeholder: { color: 'var(--neutral-400)' },
                            itemsWrapper: { zIndex: 100, width: '150%', maxHeight: '26rem' },
                          }}
                          dropdownIcon
                          onSelect={(option) => handleActionSelect(option.value)}
                          position="bottom"
                          type="button"
                          placeholder="Actions"
                          groupedOptions={groupedOptions(mappingType, {
                            isCommentEnabled: displayCommentButton,
                            isImageTaggingEnabled: !isSavingSuggestions && !editMode,
                            isLinkEnabled: !disableLinkButton && !editMode,
                            isQuickFillEnabled: isHeaderCellSelected && !editMode,
                            isAutoFillEnabled: !isSavingSuggestions && !editMode,
                            isResetEnabled: !editMode,
                            isFindFigureEnabled: isImageNodeSelected,
                          })}
                          menuType="divided"
                        />
                        <Button
                          variant="primary"
                          ref={acceptButtonRef}
                          onClick={acceptSuggestionHandler}
                          disabled={isDisabledApprove || isSavingSuggestions}>
                          Transfer
                        </Button>
                        {/* {!isAutoMapping && (
                          <>
                            <Button
                              variant="primary"
                              ref={undoButtonRef}
                              disabled={!isUndoAvailable}
                              onClick={handleGeneralizationAuthoringUndo}>
                              Undo
                            </Button>
                          </>
                        )} */}
                        <SuggestionNavigateButtons
                          isPreviousAvailable={isPreviousAuthoringCellAvailable}
                          isNextAvailable={isNextAuthoringCellAvailable}
                          navigateMapping={navigateCells}
                        />
                      </div>
                      {isAiAuthoring && (
                        <div className="flex items-center content-box-sizing space-x-3">
                          <Switch
                            checked={isNewSuggestionsActive}
                            isDisabled={editMode}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const { checked } = event.target;
                              handleReset();
                              setIsNewSuggestionsActive(checked);
                              if (!newSuggestionsVisited) {
                                setNewSuggestionsVisited(checked);
                                openNewSuggestionsModal();
                              }
                            }}
                            offColor="var(--system-50, #fff)"
                            onColor="var(--primary-200, #177ba6)"
                          />
                          <h4 className="text-neutral-400 font-normal">New Suggestions</h4>
                          <div
                            onClick={openNewSuggestionsModal}
                            className="flex justify-center items-center px-[7px] text-sm font-bold rounded-full border-2 cursor-pointer text-primary-50 border-primary-50">
                            ?
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="relative flex space-x-3 my-3 flex-grow"
                  data-testid="editor-wrapper">
                  <div className="flex-1 border border-neutral-200 rounded-[4px] p-3">
                    <TinyEditor
                      key="source"
                      editorType={EditorTypeEnum.Source}
                      disabled={isSavingSuggestions}
                      editorOptions={(editor) => {
                        editor.on('keydown', (e: KeyboardEvent) => {
                          handleKeydown(e, acceptButtonRef, editor2Ref, true);
                        });
                        editor.on('keyup', handleKeyUp);
                      }}
                      initialize={() => highlightAllSourceDocumentSuggestions()}
                      onClickHandler={editMode ? undefined : sourceDocumentOnClickHandler}
                      height="100%"
                    />
                  </div>

                  <div
                    className={`flex-1 border border-neutral-200 rounded-[4px] p-3 ${
                      editMode ? 'bg-primary-50' : ''
                    } `}>
                    <TinyEditor
                      key="target"
                      editorType={EditorTypeEnum.Target}
                      disabled={isSavingSuggestions}
                      editorOptions={(editor: TinyMCEEditor) => {
                        targetEventHandlers.forEach(({ event, handler }) => {
                          editor.on(event, (e: any) => handler(e, editor));
                        });
                      }}
                      initialize={(evt, editor) => {
                        highlightAllTargetSuggestions(editor1Ref);
                        if (!evt.paste && editor?.initialized && !editor.isDirty()) {
                          setInitialTarget(deepCopyDocument(editor1Ref?.current?.getDoc()));
                        }
                      }}
                      onClickHandler={
                        editMode
                          ? undefined
                          : (event, editor) => {
                              targetOnClickHandler(event, editor);
                              const { target }: any = event;
                              if (target.tagName !== 'IMG') {
                                closeFindFigureModal();
                              }
                            }
                      }
                      scroll={() => scrollToFirstHighlight(editor1Ref)}
                      height="100%"
                    />
                  </div>

                  <Button
                    variant="primary"
                    circular={true}
                    className="h-[42px] w-[42px] absolute -bottom-2 right-0">
                    ?
                  </Button>
                </div>
              </div>
            </div>
          )}
          <DeleteConfirmationDialogue
            isOpen={showSourceDeleteModal}
            handleClose={handleFileRemoveModalClose}
            item="file"
            handleDelete={handleRemoveSourceFile}
          />
        </Layout.Body>
      </Layout>
      <ImageTagging
        isOpen={isTaggingSlideOverOpen}
        onClose={handleClosePinningSlideOver}
        handleTag={(formData) => handleSubmitTag(formData)}
        initialFormValues={imageTagFormValues}
      />
      {isModalOpen && (
        <FileNameModal
          isOpen={isModalOpen}
          documentTitle=""
          closeModal={() => setIsModalOpen(false)}
          mappingSessionId={sessionId}
        />
      )}
      {targetChangesStatus === TargetSaveState.UNSAVED && (
        <SaveChangesModal
          handleSaveChanges={handleAuthoringSaveChanges}
          handleQuit={() => navigate('/catalog')}
          isOpen={showSaveChangesModal}
          handleClose={closeSaveChangesModal}
        />
      )}
      {isQuickFillModalOpen && (
        <QuickFillModal
          quickFillModalOpen={isQuickFillModalOpen}
          closeQuickFillModal={closeQuickFillModal}
          gvCategories={gvCategories}
          clearSelection={clearSelection}
          selectedOption={selectedOption}
          handleOptionChange={handleOptionChange}
          handleApplyFilter={handleApplyFilter}
          isLoading={isSavingFilledSuggestions}
        />
      )}
      {isFindFigureModalOpen && (
        <FindFigureModal
          isOpen={isFindFigureModalOpen}
          handleClose={closeFindFigureModal}
          imageSuggestions={imageSuggestions}
          imageSuggestionsFiles={imageSuggestionsFiles}
          targetImageNodeId={currentTargetNodeIds[0]}
          handleSourceNodeIds={handleSourceNodeIds}
          isSavingSuggestions={isSavingSuggestions}
        />
      )}
      <NewSuggestionModal isOpen={newSuggestionsModal} handleClose={closeNewSuggestionsModal} />
      <CommentsSlideOver
        comments={mappingComments}
        isOpen={showCommentsSlideOver}
        onClose={closeCommentsSlideOver}
      />
      {commentModal}
    </>
  );
};

export default Authoring;
