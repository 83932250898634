// React-related imports
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// External libraries
import {
  Header,
  SlideOverFilters,
  Tabs,
} from '@gloabal-regulatory-writing-consulting/gxt-components';

// Hooks
import { usePaginatedFilters } from '../../hooks/usePaginatedFilters';

// Components
import { Layout } from '../../components';
import { SettingsTable } from './components/SettingsTable';

// Types
import {
  CatalogItemFilterParams,
  CatalogItemParams,
  sortOrder,
  UserFilterParams,
  UserSortParams,
} from '../../services/api/types';
import { SettingsTabs as TabsType } from './types';
import { TableActionBar } from '../../components/TableActionBar';
import { ButtonConfig } from '../../components/types';
import useModal from '../../hooks/useModal';
import { useQuery } from '@tanstack/react-query';
import CatalogItem from './components/CatalogItem';
import { defaultCatalogItem } from './components/CatalogItem/utils/constants';
import { mapToFilterOptions } from '../catalog/util/helpers';
import { getCatalogItemFilterOptions } from '../../services/api';

const Settings = () => {
  const { state }: { state: { activeTab?: TabsType } } = useLocation();
  const navigate = useNavigate();

  const [defaultCatalogItemValues, setDefaultCatalogItemValues] = useState(defaultCatalogItem);
  const [activeTab, setActiveTab] = useState<TabsType>(state?.activeTab || TabsType.USER);
  const [appliedFilters, setAppliedFilters] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState('');

  const slideOverFilters = useModal();
  const createItemSlideOver = useModal();

  const showUsers = activeTab === TabsType.USER;
  const showDropdownLists = activeTab === TabsType.DROPDOWN_LISTS;

  const {
    filters,
    setFilters,
    paginatedFilteredData,
    setAppliedFiltersCount,
    setSearchByKey,
    handlePageChange,
    handlePerPageChange,
    handleColumnSort,
    appliedFiltersCount,
  } = usePaginatedFilters<
    CatalogItemFilterParams & UserFilterParams,
    CatalogItemParams & UserSortParams
  >({
    search: useMemo(
      () => ({
        query: '',
        columns: showUsers ? ['firstName', 'lastName', 'email'] : ['name'],
      }),
      [showUsers, activeTab],
    ),

    sort: useMemo(
      () => (showUsers ? { type: 'ASC', column: 'firstName' } : undefined),
      [showUsers, activeTab],
    ),
  });

  const handleActiveTab = useCallback(
    (tab: TabsType) => {
      setActiveTab(tab);
      setSearchTerm('');
      setSearchByKey('query', '');
      setFilters({});
      setAppliedFilters({});
      setAppliedFiltersCount(0);

      const sortColumn = tab === TabsType.USER ? 'firstName' : 'id';
      const order = tab === TabsType.USER ? sortOrder.ASC : sortOrder.DESC;
      handleColumnSort(order, sortColumn);
    },
    [handleColumnSort, setFilters, setSearchByKey],
  );

  const tabs = useMemo(
    () => [
      {
        title: 'Users',
        active: showUsers,
        onClickHandler: () => handleActiveTab(TabsType.USER),
      },
      {
        title: 'Dropdown lists',
        active: showDropdownLists,
        onClickHandler: () => handleActiveTab(TabsType.DROPDOWN_LISTS),
      },
    ],
    [handleActiveTab, showDropdownLists, showUsers],
  );

  const filtersQuery = useQuery({
    queryFn: getCatalogItemFilterOptions,
    queryKey: ['DropdownListFilterOptions'],
    refetchOnMount: false,
    refetchOnWindowFocus: true,
    enabled: showDropdownLists,
  });

  const actionButtons: ButtonConfig[] = useMemo(() => {
    const createButtonClick = {
      [TabsType.USER]: () => navigate('/createUser'),
      [TabsType.DROPDOWN_LISTS]: createItemSlideOver.openModal,
    };

    const createButtonInfo = {
      title: `Create ${showUsers ? 'User' : 'New'}`,
      onclick: createButtonClick[activeTab],
    };

    const commonButtons: ButtonConfig[] = [
      {
        type: 'button',
        variant: 'secondary',
        onClick: createButtonInfo.onclick,
        title: createButtonInfo.title,
        id: 'create-btn',
        dataTestId: 'create-btn',
        styles: 'whitespace-nowrap',
        disabled: false,
      },
    ];

    if (showDropdownLists) {
      const filtersButton: ButtonConfig = {
        type: 'button',
        variant: 'secondary',
        onClick: slideOverFilters.openModal,
        title: `Filter (${appliedFiltersCount})`,
        iconType: 'filter-icon',
        disabled: filtersQuery.isLoading,
        dataTestId: 'filter-btn',
        styles: 'flex justify-between items-center gap-3 whitespace-nowrap',
        id: 'filter-btn',
      };
      return [filtersButton, ...commonButtons];
    }

    return [...commonButtons];
  }, [
    activeTab,
    appliedFiltersCount,
    createItemSlideOver.openModal,
    filtersQuery.isLoading,
    navigate,
    showUsers,
    slideOverFilters.openModal,
  ]);

  const handleCloseCreateItem = () => {
    createItemSlideOver.closeModal();
    setDefaultCatalogItemValues(defaultCatalogItem);

    // Reset sort order to default
    handleColumnSort(sortOrder.DESC, 'id');
  };

  const setFiltersCount = (filters: any) => {
    const newFilters = { ...filters };
    const newAppliedFiltersCount = Object.values(newFilters).reduce((sum: number, value) => {
      if (Array.isArray(value) && value.length) {
        return sum + 1;
      }
      return sum;
    }, 0);
    setAppliedFiltersCount(newAppliedFiltersCount);
  };

  const handleApplyFilters = () => {
    setFilters(() => {
      const newFilters = { ...appliedFilters };
      setFiltersCount(newFilters);
      return newFilters;
    });

    slideOverFilters.closeModal();
  };

  const handleResetFilters = () => {
    setAppliedFilters({});
    setAppliedFiltersCount(0);
  };

  const handleCloseFilters = () => {
    slideOverFilters.closeModal();
    setAppliedFilters(filters || {});
  };

  const filtersOptionsData = useMemo(() => {
    return mapToFilterOptions(filtersQuery?.data?.data, (filters) => {
      setAppliedFilters(filters);
    }).map((val) => {
      if (val.header === 'Created By') {
        val.checkboxOptions = val.checkboxOptions.map((item) => {
          if (appliedFilters?.createdBy)
            return {
              ...item,
              checked: appliedFilters.createdBy.includes(item.value.toString()),
            };
          return item;
        });
      } else if (val.header === 'Type') {
        val.checkboxOptions = val.checkboxOptions.map((item) => {
          if (appliedFilters?.createdBy)
            return {
              ...item,
              checked: appliedFilters.type.includes(item.value.toString()),
            };
          return item;
        });
      }
      return val;
    });
  }, [filtersQuery?.data?.data, appliedFilters]);

  useEffect(() => {
    const createdByIds = filtersQuery?.data?.data?.createdBy?.options.map((op) => op.value);
    let createdByFilters: number[] | undefined;
    if (filters?.createdBy && Array.isArray(filters?.createdBy)) {
      createdByFilters = filters?.createdBy?.filter((id) => createdByIds?.includes(Number(id)));
    }

    setFiltersCount({ ...filters, createdBy: createdByFilters });
    setFilters({ ...filters, createdBy: createdByFilters });
    setAppliedFilters({ ...appliedFilters, createdBy: createdByFilters });
  }, [filtersQuery?.data?.data]);

  return (
    <Layout>
      <Layout.Header>
        <Header>
          <Header.Heading>Settings</Header.Heading>
        </Header>
      </Layout.Header>
      <Layout.Body>
        <Tabs tabs={tabs} />
        <div className="flex flex-col gap-[1rem] flex-1 w-full">
          <TableActionBar
            handleSearch={(term) => {
              setSearchTerm(term);
              setSearchByKey('query', term);
            }}
            searchTerm={searchTerm}
            buttons={actionButtons}
          />
          <SettingsTable
            activeTab={activeTab}
            paginatedFilteredData={paginatedFilteredData}
            handleColumnSort={handleColumnSort}
            handlePageChange={handlePageChange}
            handlePerPageChange={handlePerPageChange}
          />
        </div>
        <SlideOverFilters
          isOpen={slideOverFilters.show}
          onCloseHandler={handleCloseFilters}
          onApplyHandler={handleApplyFilters}
          onResetHandler={handleResetFilters}
          filtersOptions={filtersOptionsData}
          applyButtonLabel="Filter"
          closeButtonLabel="Close"
          resetButtonLabel="Clear Selection"
          title="Filters"
        />
        {createItemSlideOver.show && (
          <CatalogItem
            defaultValues={defaultCatalogItemValues}
            onClose={handleCloseCreateItem}
            isOpen={createItemSlideOver.show}
          />
        )}
      </Layout.Body>
    </Layout>
  );
};
export default Settings;
