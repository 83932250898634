import {
  Button,
  Header,
  TableHeader,
  Input,
  Dropzone,
  Badge,
} from '@gloabal-regulatory-writing-consulting/gxt-components';
import { QueryClient, useInfiniteQuery } from '@tanstack/react-query';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { useState, useMemo, useContext, ChangeEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 } from 'uuid';
import SvgIcon from '../../../components/elements/SvgIcon';
import Layout from '../../../components/layout';
import { PaginatedTable } from '../../../components/PaginatedTable';
import { CatalogContext } from '../../../contexts/CatalogContext';
import { notifyError } from '../../../helpers/utils';
import { useCatalog } from '../../../services/api';
import {
  ColumnType,
  InputProps,
  SourceFile,
  SourceFileGroup,
  CatalogItemType,
} from './UploadSource.type';
import { tableValueFormatter } from '../../../helpers/tableFormatter';
import { DocumentTabs } from '../../../types';
import useModal from '../../../hooks/useModal';
import { ItemType } from '../../../services/api/types';
import { getAllCatalogItemsWithPagination } from '../../settings/components/utils/apiHelpers';
import CatalogItemSlideOver from '../../../components/CatalogItemSlideOver';
import { createIsLinkActive } from '../../../helpers/createIsActiveLink';
import { perPageFilterOptions } from '../../../constants/catalog';

const MAX_FILES = 20;

const UploadSource = () => {
  const { pathname } = useLocation();
  const [files, setFiles] = useState<SourceFile[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPerPage, setCurrentPerPage] = useState(10);
  const [currentFile, setCurrentFile] = useState<SourceFile>();
  const catalogItemSlideOver = useModal();
  const [selectedItemType, setSelectedItemType] = useState<CatalogItemType>(ItemType.Material);

  const isLinkActive = createIsLinkActive(pathname);

  const handleCloseCreateItem = () => {
    setSelectedItemType(ItemType.Material);
    catalogItemSlideOver.closeModal();
  };

  const {
    data: catalogItemsData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [selectedItemType],
    queryFn: ({ pageParam = 1 }) =>
      getAllCatalogItemsWithPagination(selectedItemType, {
        page: pageParam,
        perPage: perPageFilterOptions,
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.prevOffset * perPageFilterOptions > lastPage.total) {
        return undefined;
      }
      return lastPage?.prevOffset + 1;
    },
    initialPageParam: 1,
  });

  const catalogItems = useMemo(
    () => catalogItemsData?.pages?.flatMap((page) => page?.items) || [],
    [catalogItemsData],
  );

  const breadcrumbItems = [
    { label: 'Catalog', to: '/Catalog' },
    { label: 'Upload Sources', to: pathname, active: true },
  ];

  const { setCatalogFilesUploadStatus } = useContext(CatalogContext);
  const navigate = useNavigate();
  const columnsHelper = createColumnHelper<ColumnType>();

  const {
    data: sitesData,
    fetchNextPage: fetchNextSitesPage,
    isFetchingNextPage: isFetchingNextSitesPage,
    hasNextPage: hasNextSitesPage,
  } = useInfiniteQuery({
    queryKey: [ItemType.Site],
    queryFn: ({ pageParam = 1 }) =>
      getAllCatalogItemsWithPagination(ItemType.Site, {
        page: pageParam,
        perPage: perPageFilterOptions,
      }),
    getNextPageParam: (lastPage) => {
      if (lastPage.prevOffset * perPageFilterOptions > lastPage.total) {
        return undefined;
      }
      return lastPage?.prevOffset + 1;
    },
    initialPageParam: 1,
  });

  const siteOptions = useMemo(
    () =>
      sitesData?.pages?.flatMap(
        (page) =>
          page?.items.map(({ id, name }) => ({
            value: id?.toString(),
            label: name,
          })),
      ) || [],
    [sitesData],
  );

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target as HTMLElement;
    if (
      scrollHeight - scrollTop <= clientHeight &&
      ((hasNextPage && !isFetchingNextPage) || (hasNextSitesPage && !isFetchingNextSitesPage))
    ) {
      catalogItemSlideOver.show ? fetchNextPage() : fetchNextSitesPage();
    }
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, info: CellContext<ColumnType, any>) => {
    const { name, value } = e.target;
    const fileId = info.row.original.id;

    setFiles((prevFiles) => {
      return prevFiles.map((file) => {
        if (file.id === fileId) {
          return { ...file, [name]: value };
        }
        return file;
      });
    });
  };

  const handleGroupModalClick = (tempSelectedItems: SourceFileGroup[]) => {
    if (selectedItemType) {
      setFiles((prevFiles) =>
        prevFiles.map((file) => {
          if (file === currentFile) {
            return { ...file, [selectedItemType]: tempSelectedItems };
          }
          return file;
        }),
      );
      handleCloseCreateItem();
    }
  };

  const getColumn = ({
    name,
    title,
    required = false,
    size = 50,
    type = 'text',
    placeholder = '',
  }: InputProps) => ({
    header: () => (
      <TableHeader handleColumnSort={() => {}} Title={required ? `${title}*` : title} />
    ),
    cell: (info: CellContext<ColumnType, any>) => {
      const handleClick = () => {
        if (name === ItemType.Material) {
          setCurrentFile(info.row.original as SourceFile);
          setSelectedItemType(ItemType.Material);
          catalogItemSlideOver.openModal();
        }
        if (name === ItemType.ProcessID) {
          setCurrentFile(info.row.original as SourceFile);
          setSelectedItemType(ItemType.ProcessID);
          catalogItemSlideOver.openModal();
        }
        if (name === ItemType.DPImage) {
          setCurrentFile(info.row.original as SourceFile);
          setSelectedItemType(ItemType.DPImage);
          catalogItemSlideOver.openModal();
        }
        if (name === ItemType.Site) {
          setCurrentFile(info.row.original as SourceFile);
          setSelectedItemType(ItemType.Site);
          catalogItemSlideOver.openModal();
        }
      };

      if ([ItemType.Material, ItemType.ProcessID, 'Site', ItemType.DPImage].includes(name)) {
        const items = info.getValue() || [];
        return (
          <div
            className="flex flex-wrap gap-2 cursor-pointer min-h-[38px] p-2 border border-gray-300 rounded"
            onClick={handleClick}>
            {items.length > 0 ? (
              items.map((item: any, index: number) => (
                <Badge
                  key={`${item.id}-${index}`}
                  type="basic"
                  label={item.name}
                  showIcon={false}
                  className="!bg-gray-100"
                />
              ))
            ) : (
              <span className="text-gray-400">{placeholder}</span>
            )}
          </div>
        );
      }

      return (
        <Input
          id={info.row.original.id}
          className="invalid:border-negative-200"
          key={info.row.original.id}
          value={tableValueFormatter(info)}
          step={0.1}
          min={0.1}
          type={type}
          placeholder={placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e, info)}
          name={name}
          required={required}
        />
      );
    },
    size: size,
  });

  const columns = useMemo(
    () => [
      columnsHelper.accessor(
        'title',
        getColumn({ name: 'title', title: 'Veeva Document Name', required: true, size: 200 }),
      ),
      columnsHelper.accessor(
        ItemType.Material,
        getColumn({
          name: ItemType.Material,
          title: 'Material',
          required: true,
          placeholder: 'Select Material',
        }),
      ),
      columnsHelper.accessor(
        ItemType.ProcessID,
        getColumn({
          name: ItemType.ProcessID,
          title: 'Process ID',
          placeholder: 'Select Process ID',
        }),
      ),
      columnsHelper.accessor(
        ItemType.DPImage,
        getColumn({ name: ItemType.DPImage, title: 'DP Image', placeholder: 'Select DP Image' }),
      ),
      columnsHelper.accessor(
        ItemType.Site,
        getColumn({ name: ItemType.Site, title: 'Site', placeholder: 'Select Site' }),
      ),
      columnsHelper.accessor(
        'documentNum',
        getColumn({ name: 'documentNum', title: 'Veeva No.', placeholder: 'Veeva No.' }),
      ),
      columnsHelper.accessor(
        'version',
        getColumn({
          name: 'version',
          title: ' Veeva Doc Version',
          required: true,
          type: 'number',
          placeholder: '1.00',
        }),
      ),
      columnsHelper.accessor('id', {
        header: () => <TableHeader handleColumnSort={() => {}} Title="" />,
        cell: (info: CellContext<ColumnType, any>) => (
          <div
            className="flex justify-center items-center cursor-pointer"
            onClick={() => handleDelete(info.row.original.id)}>
            <SvgIcon iconType="delete-light-border" />
          </div>
        ),
      }),
    ],
    [siteOptions],
  );

  const handleOnDrop = (newFiles: File[]) => {
    if (newFiles.length > MAX_FILES) {
      notifyError(`You can upload a maximum of ${MAX_FILES} files`);
      return;
    }
    const updatedFiles: SourceFile[] = newFiles.map((file) => ({
      id: v4(),
      title: file.name,
      // prettier-ignore
      version: 1.00.toFixed(2),
      sourceType: 'source',
      [ItemType.Material]: [],
      [ItemType.ProcessID]: [],
      [ItemType.Site]: [],
      [ItemType.DPImage]: [],
      file: file,
      documentNum: '',
    }));
    const totalFiles = updatedFiles.length + files.length;
    if (totalFiles > MAX_FILES) {
      notifyError(`You can upload a maximum of ${MAX_FILES} files`);
      setFiles((prev) => [...prev, ...updatedFiles].slice(0, MAX_FILES));
    } else {
      setFiles((prev) => [...prev, ...updatedFiles]);
    }
  };

  const handleSelectAll = (_currentPageFiles: ColumnType[], newSelection: string[]) => {
    setSelectedFiles(newSelection);
  };

  const handleOnSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    setSelectedFiles((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((id) => id !== value);
      }
    });
  };

  const validFiles = useMemo(
    () =>
      files.length > 0 &&
      files.every(
        ({ title, version, Material }) =>
          title &&
          version &&
          +version < 1000 &&
          (version.toString().includes('.')
            ? version.toString()?.split('.')[1]?.length <= 2
            : true) &&
          Material.length,
      ),
    [files],
  );

  const queryClient = new QueryClient();
  const { addToCatalog } = useCatalog();

  const handleOnSubmit = () => {
    setCatalogFilesUploadStatus((prev) => prev.filter((status) => status.status === 'pending'));
    const promises = files.map((file) => {
      setCatalogFilesUploadStatus((prev) => [
        ...prev,
        { id: file.id, status: 'pending', title: file.title },
      ]);
      const formData = new FormData();
      for (const key in file) {
        const value = file[key as keyof SourceFile];
        if (key === ItemType.Material) {
          formData.append('materialIds', JSON.stringify(file[key].map((item) => item.id)));
        } else if (key === ItemType.ProcessID) {
          formData.append('processIds', JSON.stringify(file[key].map((item) => item.id)));
        } else if (key === ItemType.Site) {
          formData.append('siteIds', JSON.stringify(file[key].map((item) => item.id)));
        } else if (key === ItemType.DPImage) {
          formData.append('dpImageIds', JSON.stringify(file[key].map((item) => item.id)));
        } else {
          formData.append(key, value as string | Blob);
        }
      }
      return addToCatalog
        .mutateAsync(formData)
        .then(() => {
          setCatalogFilesUploadStatus((prev) =>
            prev.map((status) =>
              status.id === file.id ? { ...status, status: 'success' } : status,
            ),
          );
        })
        .catch(() => {
          setCatalogFilesUploadStatus((prev) =>
            prev.map((status) => (status.id === file.id ? { ...status, status: 'error' } : status)),
          );
        });
    });
    Promise.all(promises).then(() => {
      queryClient.invalidateQueries({ queryKey: ['Catalog'] });
    });
    navigateToSourceDocuments();
  };

  const navigateToSourceDocuments = () => {
    navigate('/list', {
      state: {
        activeTab: DocumentTabs.SOURCE,
      },
    });
  };
  const handleAddCatalogItems = (items: SourceFileGroup[]) => {
    if (selectedItemType) {
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          (selectedFiles.length > 0 && selectedFiles.includes(file.id)) ||
          currentFile?.id === file.id
            ? { ...file, [selectedItemType]: [...items] }
            : file,
        ),
      );

      handleGroupModalClick(items);
      handleCloseCreateItem();
    }
  };

  const handleDelete = (id: string) => {
    setFiles((prev) => prev.filter((file) => file.id !== id));
    setSelectedFiles((prev) => prev.filter((fileId) => fileId !== id));
  };

  return (
    <>
      <Layout>
        <Layout.Header>
          <Header breadcrumbItems={breadcrumbItems} isLinkActive={isLinkActive}>
            <Header.Heading>Upload Documents</Header.Heading>
            <Header.Actions>
              <Button variant="secondary" disabled={!validFiles} onClick={handleOnSubmit}>
                Add to Catalog
              </Button>
              <Button variant="secondary" onClick={navigateToSourceDocuments}>
                Cancel
              </Button>
            </Header.Actions>
          </Header>
        </Layout.Header>
        <Layout.Body>
          <div className="gap-[1.5rem] flex flex-col items-start flex-1 w-full">
            <div className="flex justify-between items-center self-stretch">
              <p className="text-primary-300">Upload a maximum of {MAX_FILES} files at a time</p>
              <p className="text-primary-200 font-normal text-xs">
                Items Marked with a * are required
              </p>
            </div>
            <div className="gap-[1rem] flex flex-col items-start flex-1 w-full">
              <div className="w-full h-[5.32rem]">
                <Dropzone maxFiles={MAX_FILES} onDrop={handleOnDrop} />
              </div>
              {files.length === 0 ? (
                <div className="w-full h-full flex items-center justify-center bg-white border rounded-md">
                  <p className="text-gray-500">No uploaded documents</p>
                </div>
              ) : (
                <PaginatedTable<ColumnType>
                  additionalColumns={columns}
                  menuItems={[]}
                  paginatedFilteredData={{
                    pagination: { page: currentPage, perPage: currentPerPage },
                  }}
                  getData={(() => {}) as any}
                  clientSideData={files}
                  clientSidePagination={true}
                  handleSelectAll={handleSelectAll}
                  onSelect={handleOnSelect}
                  selectedData={selectedFiles}
                  handlePageChange={setCurrentPage}
                  handlePerPageChange={setCurrentPerPage}
                />
              )}
              {catalogItemSlideOver.show && (
                <CatalogItemSlideOver
                  onClose={handleCloseCreateItem}
                  isOpen={catalogItemSlideOver.show}
                  heading={`Assign ${
                    {
                      [ItemType.Material]: ItemType.Material,
                      [ItemType.ProcessID]: ItemType.ProcessID,
                      [ItemType.DPImage]: ItemType.DPImage,
                      [ItemType.Site]: ItemType.Site,
                    }[selectedItemType] || ''
                  }`}
                  catalogItems={catalogItems || []}
                  selectedItems={(currentFile?.[selectedItemType] || []) as SourceFileGroup[]}
                  handleResetSelection={() => {}}
                  handleAdd={handleAddCatalogItems}
                  handleScroll={handleScroll}
                  fetchingNextPage={isFetchingNextPage}
                  isLoading={isLoading}
                />
              )}
            </div>
          </div>
        </Layout.Body>
      </Layout>
    </>
  );
};

export default UploadSource;
