import { z, ZodType } from 'zod';

export const createCatalogItemsSchema = (fields: string[]): z.ZodObject<z.ZodRawShape> => {
  const shape = fields.reduce(
    (acc, field, index) => {
      if (index === fields.length - 1 && index !== 0) {
        acc[field] = z.string().optional();
      } else {
        acc[field] = z.string().min(1, { message: 'Item name is required' });
      }
      return acc;
    },
    {} as Record<string, ZodType<any>>,
  );

  return z.object({
    ...shape,
  });
};
