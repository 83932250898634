// API services
import { getCatalogFilterOptions, getMappingSessionsFilterOptions } from '../../../services/api';

// Types
import { DocumentTabs } from '../../../types';
import {
  GetCatalogItemFilterOptionsResponse,
  GetMappingSessionsFilterOptionsResponse,
  GetSourceFilterOptionsResponse,
} from '../../../services/api/types';
import { FilterOptions } from '@gloabal-regulatory-writing-consulting/gxt-components/dist/components/slideOverFilters/SlideOverFilters';
import { Option } from '../../../components/types';
import { FILTER_OPTIONS } from './constants';

const mapToFilterOptions = (
  filterOptions:
    | Partial<GetMappingSessionsFilterOptionsResponse>
    | Partial<GetSourceFilterOptionsResponse>
    | Partial<GetCatalogItemFilterOptionsResponse>
    | undefined,
  checkboxOptionOnChange?: (filters: object) => void,
) => {
  return Object.entries(filterOptions || {}).reduce((acc, [_key, filterOptions]) => {
    const checkboxOptions = (filterOptions?.options || [])
      .sort((a, b) => a.label.localeCompare(b.label))
      .reduce(
        (acc, filterOption) => {
          if (filterOption.value) {
            acc.push({
              label: `${filterOption.label}`,
              name: _key,
              value: filterOption.value,
              onChange: checkboxOptionOnChange,
            });
          }
          return acc;
        },
        [] as FilterOptions['checkboxOptions'],
      );

    if (checkboxOptions.length) {
      acc.push({
        header: filterOptions.label,
        isAccordionOpen: true,
        isSearchAble: filterOptions.options.length >= FILTER_OPTIONS.SEARCH_THRESHOLD,
        onChangeCallback: () => {},
        checkboxOptions: checkboxOptions,
      });
    }
    return acc;
  }, [] as FilterOptions[]);
};

const getFilterFetcher = (activeTab: DocumentTabs, templateType: Option['value'] = 'Target') => {
  switch (activeTab) {
    case DocumentTabs.SOURCE:
      return getCatalogFilterOptions;
    case DocumentTabs.TARGET:
      return templateType === 'Target' ? getCatalogFilterOptions : getMappingSessionsFilterOptions;
    case DocumentTabs.IN_PROGRESS:
    case DocumentTabs.COMPLETED:
      return getMappingSessionsFilterOptions;
    default:
      throw new Error(`Unhandled tab type: ${activeTab}`);
  }
};

export const setFilterSearchability = (data: GetMappingSessionsFilterOptionsResponse): void => {
  Object.values(data).forEach((filter) => {
    filter.isSearchAble = filter.options.length >= FILTER_OPTIONS.SEARCH_THRESHOLD;
  });
};

export { mapToFilterOptions, getFilterFetcher };
