// React related imports
import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

// Contexts and Hooks
import { useCatalog } from '../../services/api/catalog/useCatalog';

// Components
import TinyEditor from '../../components/TinyEditor';
import Layout from '../../components/layout';
import TemplateFields from './TemplateFields';
import { Header, Button } from '@gloabal-regulatory-writing-consulting/gxt-components';

// Helpers
import { notifyError, notifySuccess } from '../../helpers/utils';
import { extractCategory, getDocumentNum } from '../../helpers/catalogHelpers';

// Types
import { DocumentTabs, EditorRef, EditorTypeEnum } from '../../types';

// Constants
import { initialValues } from './utils/constants';

// Others
import { zodResolver } from '@hookform/resolvers/zod';
import { templateUploadSchema } from './utils/schemas';
import { generateCatalogTemplatesIdsList } from './utils/helpers';

const CatalogTemplateForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditorDirty, setIsEditorDirty] = useState(false);
  const [isEditorFocused, setIsEditorFocused] = useState(false);

  const tinyEditorRef = useRef<EditorRef>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { external = false } = location.state || {};

  const { addToCatalog, convertBlobToBase64Query } = useCatalog();

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
    getValues,
    setValue,
  } = useForm({
    resolver: zodResolver(templateUploadSchema),
    mode: 'onChange',
  });

  const isTemplateValidated = isValid && isEditorDirty && !isEditorFocused && !isLoading;

  const handleEditorOnBlur = () => {
    const body = tinyEditorRef.current?.getBody() as HTMLBodyElement;
    const textContent = body.innerHTML.replace(/\s+/g, '').length > 1;
    setIsEditorDirty(textContent);
    setIsEditorFocused(false);
  };

  const handleEditorOnFocus = () => setIsEditorFocused(true);

  const addTemplateToCatalog = async () => {
    const data = getValues();
    setIsLoading(true);
    if (!isTemplateValidated) return;
    const body = tinyEditorRef.current?.getBody() as HTMLBodyElement;
    const editorContent = body.innerHTML;

    if (
      !editorContent ||
      editorContent.replace(/<[^>]*>/g, '').trim() === '' ||
      body?.textContent?.trim() === ''
    ) {
      notifyError('Missing content in editor');
      setIsLoading(false);
      return;
    }

    const html = await convertBlobToBase64Query.mutateAsync(editorContent);

    if (!html) {
      notifyError('Missing content in editor');
      return;
    }

    const catalogData = {
      ...initialValues,
      documentNum: getDocumentNum(data.templateName),
      title: data.templateName,
      documentName: data.templateName,
      documentCat: extractCategory(data.templateName),
      originalName: data.templateName,
      version: +data.version,
      ctdSectionIds: generateCatalogTemplatesIdsList(data.ctdSections),
      regions: generateCatalogTemplatesIdsList(data.regions),
      documentType: external ? DocumentTabs.EXTERNAL : initialValues.documentType,
    };

    addToCatalog
      .mutateAsync({ ...catalogData, value: html })
      .then(() => {
        notifySuccess('Template added successfully');
        navigate('/list', {
          state: {
            activeTab: DocumentTabs.TARGET,
            templateType: {
              title: `${external ? 'External Templates' : 'Manual Templates'}`,
              value: `${external ? 'External' : 'Target'}`,
            },
          },
        });
      })
      .catch((err) => {
        notifyError(err.message);
      });
  };

  return (
    <Layout>
      <Layout.Header>
        <div className="flex flex-col items-start justify-center p-6 pb-3 flex-1 self-stretch">
          <div className="flex justify-between items-center self-stretch">
            <Header.Heading className={'!text-primary-300'}>Upload Documents</Header.Heading>
            <Header.Actions>
              <Button
                variant="secondary"
                disabled={!isTemplateValidated}
                onClick={handleSubmit(addTemplateToCatalog)}>
                Add to Catalog
              </Button>
              <Button variant="secondary" onClick={() => navigate('/catalog')}>
                Cancel
              </Button>
            </Header.Actions>
          </div>
        </div>
      </Layout.Header>
      <Layout.Body>
        <div className="flex items-start gap-4 flex-1 self-stretch ">
          <TemplateFields
            control={control}
            errors={errors}
            setValue={setValue}
            external={external}
          />
          <div className="flex flex-col gap-6 justify-start flex-1 min-h-0 self-stretch w-full md:w-2/3 md:order-last">
            <p className="text-primary-300 font-bold text-[1.25rem] leading-[1.875rem] tracking-[0.0015rem]">
              {external
                ? 'Copy the content of your external document and paste it in the editor below*'
                : 'Copy paste your template here'}
            </p>
            <TinyEditor
              key="target"
              editorType={EditorTypeEnum.Target}
              disabled={false}
              ref={tinyEditorRef}
              height="70vh"
              menuBar={false}
              toolBar={false}
              onBlur={handleEditorOnBlur}
              onFocus={handleEditorOnFocus}
            />
          </div>
        </div>
      </Layout.Body>
    </Layout>
  );
};

export default CatalogTemplateForm;
