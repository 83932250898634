// React-related imports
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

// React Hook Form imports
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
// import { zodResolver } from '@hookform/resolvers/zod';

// Component imports
import { Dropdown, Input } from '@gloabal-regulatory-writing-consulting/gxt-components';
import { InputError } from '../../../../components/inputError';

// Type and utility imports
import { CatalogItemFormProps, CatalogItemFormValues } from './CatalogItem.types';
import { CatalogItems, ItemType } from '../../../../services/api/types';
import { getDropdownOptions } from '../../../../helpers/utils';
import { createCatalogItemsSchema } from './utils/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { DropdownCustomStyles } from './utils/constants';

// API imports
import { useQuery } from '@tanstack/react-query';
import { getAllCatalogItems } from '../utils/apiHelpers';

const CatalogItemForm = forwardRef(
  (
    {
      handleSubmitData,
      defaultValues,
      updateFormValidity,
      itemCount,
      fields,
    }: CatalogItemFormProps,
    ref,
  ) => {
    const [itemType, setItemType] = useState(defaultValues?.type);
    const [material, setMaterial] = useState<CatalogItems | null>(null);
    fields = fields || Array.from({ length: itemCount }, (_, index) => `name${index + 1}`);

    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
      watch,
    } = useForm<CatalogItemFormValues>({
      defaultValues,
      resolver: zodResolver(createCatalogItemsSchema(fields)),
      mode: 'onChange',
    });

    const fieldValues = watch();

    const areFieldsValidForButton = useMemo(() => {
      return fields?.every((field) => (fieldValues[field] as string)?.trim().length > 0);
    }, [fields, fieldValues]);

    const onSubmit: SubmitHandler<CatalogItemFormValues> = (data: CatalogItemFormValues) => {
      if (itemType) {
        handleSubmitData({
          type: itemType,
          associatedCatalogItemId: material?.id,
          ...data,
        });
        reset({ name1: '' });
      }
    };

    const { data: materials, isLoading: materialsLoading } = useQuery({
      queryKey: [ItemType.Material + 'settings'],
      queryFn: () => getAllCatalogItems(ItemType.Material, { page: 1, perPage: 100000 }),
      placeholderData: (previousData) => previousData,
    });

    useImperativeHandle(ref, () => ({
      submitForm: handleSubmit(onSubmit),
    }));

    useEffect(() => {
      const isFormValid =
        !!areFieldsValidForButton && !!itemType && (itemType !== ItemType.ProcessID || !!material);
      updateFormValidity(isFormValid);
    }, [areFieldsValidForButton, itemType, material, updateFormValidity]);

    const typeDropdownPlaceholder = !defaultValues.id ? 'Select type' : '';
    const groupDropdownPlaceholder = !defaultValues.id ? 'Select a material' : '';

    const initialType = useMemo(
      () => ({ value: itemType || defaultValues?.type }),
      [defaultValues?.type, itemType],
    );

    const initialMaterial = useMemo(
      () => ({
        value: materials
          ? materials?.find(
              (g) => g.id === (material?.id || defaultValues?.associatedCatalogItemId),
            )
          : null,
      }),
      [defaultValues?.associatedCatalogItemId, material?.id, materials],
    );

    useEffect(() => {
      setMaterial(initialMaterial?.value || null);
      setItemType(initialType?.value);
    }, [initialMaterial, initialType]);

    return (
      <form
        className="flex flex-col items-start gap-5 flex-1 flex-shrink-0 basis-0 w-full"
        data-testid="catalog-item-form">
        <Dropdown
          options={getDropdownOptions(Object.values(ItemType))}
          type="select"
          position="center"
          dropdownIcon
          label="Choose a type"
          placeholder={typeDropdownPlaceholder}
          initialValue={initialType}
          disabled={materialsLoading}
          onSelect={(option) => setItemType(option?.value)}
          renderOption={(option) => <>{option?.value}</>}
          customStyles={DropdownCustomStyles}
        />
        {itemType === ItemType.ProcessID && (
          <Dropdown
            options={getDropdownOptions(materials?.map((material) => material) || [])}
            type="select"
            position="center"
            dropdownIcon
            label="Choose a material"
            initialValue={initialMaterial}
            equalityFn={(a, b) => a?.value?.id === b?.value?.id}
            placeholder={groupDropdownPlaceholder}
            disabled={materialsLoading}
            onSelect={(option) => setMaterial(option.value as CatalogItems)}
            renderOption={(option) => <>{option.value?.name}</>}
            customStyles={DropdownCustomStyles}
          />
        )}
        {itemCount > 0 &&
          fields.map((value: string) => (
            <Controller
              key={value}
              name={value}
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  id={value}
                  data-testid={value}
                  label={`Item name`}
                  placeholder={`Item name`}
                  isFilled={!!field.value}
                  customStyles={{
                    container: { width: '100%' },
                  }}
                  error={!!errors[`${value}`]?.message}
                  helpText={<InputError errors={errors} field={value} />}
                />
              )}
            />
          ))}
      </form>
    );
  },
);

CatalogItemForm.displayName = 'CatalogItemForm';

export default CatalogItemForm;
