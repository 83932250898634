/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
// React-related imports
import { ChangeEvent, useEffect, useState } from 'react';

// Components
import {
  Button,
  CheckboxInput,
  Search,
  SlideOver,
} from '@gloabal-regulatory-writing-consulting/gxt-components';

// Helpers
import { capitalizeFirstLetter } from '../../../../helpers/utils';

// Types
import { RolesSlideOverProps } from './RolesSlideOver.types';
import SvgIcon from '../../../../components/elements/SvgIcon';

const RolesSlideOver: React.FC<RolesSlideOverProps> = ({
  isOpen,
  onClose,
  handleSave,
  onCloseSlideOver,
  selectedRoles,
  allRoles,
  heading = 'Assign Roles',
  isBackButton = false,
  backButtonOnClick = () => {},
}: RolesSlideOverProps) => {
  const [rolesSelected, setRolesSelected] = useState(selectedRoles);
  const [filteredRoles, setFilteredRoles] = useState(allRoles);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    if (checked) {
      setRolesSelected((prev) => [...prev, Number(value)]);
    } else {
      setRolesSelected((prev) => prev.filter((id) => id !== Number(value)));
    }
  };

  useEffect(() => {
    setRolesSelected(selectedRoles);
  }, [selectedRoles]);

  const handleSearchChange = (term: string) => {
    setFilteredRoles(
      term.trim() === ''
        ? allRoles
        : allRoles.filter((role: { name: string }) =>
            role.name.toLowerCase().includes(term.toLowerCase()),
          ),
    );
  };

  return (
    <SlideOver
      isOpen={isOpen}
      onClose={onClose}
      width="28rem"
      mountElementId="styled-wrapper"
      dataTestId="roles-slideover">
      <SlideOver.Header className="bg-primary-50 text-white">
        {isBackButton && (
          <div
            className="flex items-center gap-1 text-[16px] font-[400] cursor-pointer"
            onClick={backButtonOnClick}>
            <SvgIcon iconType="arrow-back" />
            Back
          </div>
        )}
        {heading}
      </SlideOver.Header>
      <div className="flex flex-col items-start gap-4 self-stretch">
        {allRoles.length > 5 && (
          <div className="flex w-full pr-2">
            <Search onChangeCallback={handleSearchChange} placeholder="Search..." />
          </div>
        )}
        {filteredRoles.map((role: any) => (
          <div key={role.id} className="flex items-center gap-2">
            <label
              htmlFor={`role-${role.id}`}
              className="flex items-center gap-2 text-base font-normal cursor-pointer">
              <CheckboxInput
                $inputSize="xs"
                id={`role-${role.id}`}
                value={role.id}
                checked={rolesSelected.includes(role.id)}
                onChange={handleOnChange}
              />
              {capitalizeFirstLetter(role.name)}
            </label>
          </div>
        ))}
      </div>
      <SlideOver.Footer className="flex flex-col items-center self-stretch border-t !border-neutral-100">
        <div className="flex gap-4 justify-end items-center flex-grow flex-shrink flex-basis-0 w-full">
          <Button variant="primary" onClick={() => handleSave(rolesSelected)}>
            Save
          </Button>
          <Button variant="secondary" onClick={onCloseSlideOver}>
            Close
          </Button>
        </div>
      </SlideOver.Footer>
    </SlideOver>
  );
};

export default RolesSlideOver;
